import { AttachFile, Close } from '@mui/icons-material'
import { Button, Divider } from '@mui/material'
import Typography from '@mui/material/Typography'
import { MuiFileInput } from 'mui-file-input'
import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Form, { FormField, GroupFormField } from '~/components/Form'
import Col from '~/components/UI/Grid/Col'
import Row from '~/components/UI/Grid/Row'
import exists from '~/helpers/utils/exists'
import { useUpdateCommonMutation } from '~/redux/features/api/account-api-slice'
import { authActions, selectAuth } from '~/redux/features/auth/auth-slice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { useZodProfile } from './useZodProfile'

const ProfileSection: FC<{
  alignContent?: 'left' | 'center' | 'right'
  title?: string
  description?: string
}> = ({
  alignContent = 'left',
  title = 'My Information',
  description = 'Manage your account information',
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [updateCommon, { isLoading }] = useUpdateCommonMutation()
  const { authenticated, account_detail, ...restAuth } = useAppSelector(selectAuth)
  const { settings, avatar, certificate, ...defaultValues } = account_detail || {}

  const {
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, dirtyFields, defaultValues: formDefaults },
  } = useZodProfile({ ...defaultValues })

  const isDoctor = defaultValues.account_type === 'doctor'

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const onSubmit = async () => {
    // Extract dirty fields and their values
    const dirtyData = Object.keys(dirtyFields).reduce(
      (acc, key) => Object.assign(acc, { [key]: getValues(key as any) }),
      {},
    )

    if (!exists(dirtyFields)) return void 0

    // Handle form submission here
    try {
      const profileUpdated = await updateCommon(dirtyData).unwrap()

      if (profileUpdated) {
        const profile = {
          ...restAuth,
          authenticated,
          account_detail: { ...account_detail, ...profileUpdated },
        }

        dispatch(authActions.setCredentials(profile))
        const { avatar, certificate, ...restProfileUpdated } = profileUpdated

        console.log('\n === profileUpdated ===')
        console.log({
          ...formDefaults,
          ...restProfileUpdated,
        })
        console.log('===\n')

        reset({
          ...formDefaults,
          ...restProfileUpdated,
        })
      }
    } catch (err: any) {
      console.error(err)
    }
  }

  const watchedValues = watch()

  useEffect(() => {
    const valuesAreDifferent = Object.keys(getValues()).some((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const boolean = formDefaults[key] !== getValues()[key]

      return boolean
    })

    setIsSubmitDisabled(!valuesAreDifferent)
  }, [dirtyFields])

  return (
    <Row>
      <Col xs={12}>
        <Typography variant="h4" mb={2} sx={{ textAlign: alignContent }}>
          {title}
        </Typography>

        <Typography variant="body1" mb={3} sx={{ textAlign: alignContent }}>
          {description}
        </Typography>
      </Col>

      <Col xs={12}>
        <Form noValidate onSubmit={handleSubmit(onSubmit)} register={register}>
          <GroupFormField>
            <FormField
              name="first_name"
              label={t('first_name')}
              register={register}
              errors={errors}
            />

            <FormField
              name="last_name"
              label={t('last_name')}
              register={register}
              errors={errors}
            />
          </GroupFormField>

          <GroupFormField>
            <FormField name="email" label={t('email')} register={register} errors={errors} />
          </GroupFormField>

          <Divider sx={{ marginY: 3 }} />

          <GroupFormField>
            <FormField
              type="password"
              name="password"
              label={t('password')}
              register={register}
              errors={errors}
            />

            <FormField
              type="password"
              name="password2"
              label={t('password')}
              register={register}
              errors={errors}
            />
          </GroupFormField>

          <Divider sx={{ marginY: 3 }} />

          <GroupFormField mt={3}>
            {isDoctor && (
              <Controller
                name="certificate"
                control={control}
                render={({ field, fieldState }) => (
                  <MuiFileInput
                    {...field}
                    size="small"
                    multiple={false}
                    label={t('ui.form.certificate')}
                    inputProps={{ accept: 'application/pdf' }}
                    clearIconButtonProps={{ children: <Close fontSize="small" /> }}
                    InputProps={{ startAdornment: <AttachFile fontSize="medium" /> }}
                    error={fieldState.invalid}
                    helperText={fieldState.invalid ? 'File is invalid' : ''}
                    placeholder={t('ui.form.choose_file')}
                    onChange={(value: File | null) => field.onChange(value)}
                    value={field.value instanceof File ? field.value : null}
                  />
                )}
              />
            )}

            <Controller
              name="avatar"
              control={control}
              render={({ field, fieldState }) => (
                <MuiFileInput
                  {...field}
                  size="small"
                  multiple={false}
                  label={t('ui.form.avatar')}
                  inputProps={{ accept: 'image/jpeg,image/png,image/gif,image/webp' }}
                  clearIconButtonProps={{ children: <Close fontSize="small" /> }}
                  InputProps={{ startAdornment: <AttachFile fontSize="medium" /> }}
                  error={fieldState.invalid}
                  helperText={fieldState.invalid ? t('ui.form.avatar_invalid_format') : ''}
                  placeholder={t('ui.form.choose_file')}
                  value={field.value instanceof File ? field.value : null}
                />
              )}
            />
          </GroupFormField>

          <Button
            color="primary"
            disabled={isLoading || isSubmitDisabled}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
          >
            {t('update')}
          </Button>
        </Form>
      </Col>
    </Row>
  )
}
export default ProfileSection
