import MuiContainer, { ContainerProps as MuiContainerProps } from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

export type ContainerProps = MuiContainerProps

const ContainerStyled = styled(MuiContainer)<Omit<ContainerProps, 'key'>>(({ theme }) => {
  return {
    width: '100%',
    marginInline: 'auto',
  }
})

const Container = forwardRef<HTMLDivElement, Omit<ContainerProps, 'key'>>(
  ({ children, ...rest }, ref) => (
    <ContainerStyled ref={ref} {...rest}>
      {children}
    </ContainerStyled>
  ),
)
Container.displayName = 'Container'

export default Container
