import button from './button'
import cookies from './cookies'
import form from './form'
import locale from './locales'
import myProfile from './myProfile'
import navigation from './navigation'
import newsletter from './newsletter'
import testimonials from './testimonials'

const ui = {
  button,
  cookies,
  forgotPassword: 'Forgot password?',
  dontHaveAccount: 'Don`t have an account, Sign Up!',
  findUs: 'Find us here',
  form,
  locale,
  management: { clients: 'Clients' },
  myProfile,
  navigation,
  newsletter,
  testimonials,
}

export default ui
