import styled from '@emotion/styled'
import MuiCard from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'

export const Card = styled(MuiCard)({})

export const CardAction = styled(CardActionArea)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}))
