import { Box, Button, Card, Fade, Grid, Stack, Switch, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cookies as cookiesConfig } from '~/config/cookies-config'
import { useUpdateSettingsMutation } from '~/redux/features/api/account-api-slice'
import { cookiesActions } from '~/redux/features/cookies/cookies-slice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'

const CookieConsentFooter = () => {
  const authenticated = useAppSelector((state) => state.auth.authenticated)
  const cookies = useAppSelector((state) => state.cookies)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [open, setOpen] = useState(cookies.cookies.length < 1)
  const [showSettings, setShowSettings] = useState(false)

  useEffect(() => {
    setOpen(cookies.cookies.length < 1)
  }, [authenticated, cookies])

  const [cookiesSettings, setCookiesSettings] = useState(
    cookiesConfig.list.map((cookie) => {
      return { ...cookie, accepted: true }
    }),
  )

  const [updateSettings] = useUpdateSettingsMutation()

  const onButtonClick = (acceptAll: boolean) => {
    updateCookies(
      cookiesConfig.list.map((cookie) => {
        return {
          ...cookie,
          accepted: acceptAll || cookie.type === 'functional',
        }
      }),
    )
  }

  const onSaveSettings = () => {
    updateCookies(cookiesSettings)
  }

  const updateCookies = async (data: { type: string; accepted: boolean }[]) => {
    setOpen(false)

    const body: any = {
      cookie_set: [],
    }

    data.forEach((cookie) => {
      body.cookie_set = [...body.cookie_set, { type: cookie.type, accepted: cookie.accepted }]
    })

    dispatch(cookiesActions.setCookies({ cookies: body.cookie_set }))
    setShowSettings(false)

    if (authenticated) {
      const settingsResponse = await updateSettings(body).unwrap()
      console.log(settingsResponse)
    }
  }

  return (
    <Fade in={open}>
      <Card
        elevation={6}
        sx={{
          borderRadius: 2,
          position: 'fixed',
          bottom: 15,
          right: 15,
          width: '550px',
          zIndex: 99999,
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <Stack direction={'column'}>
          <Typography component="h2" variant="body1" textAlign={'center'} paddingBottom={0.5}>
            {t('ui.cookies.title')}
          </Typography>
          <Typography
            component="h2"
            variant="caption"
            textAlign={'justify'}
            lineHeight={1.4}
            marginBottom={0.5}
          >
            {t('ui.cookies.description')}
          </Typography>

          {showSettings && (
            <Box width={'100%'} marginBottom={1}>
              {cookiesSettings.map((cookie) => (
                <Box
                  key={cookie.type}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography component="h2" variant="caption">
                    {t(`ui.cookies.types.${cookie.type}`)}
                  </Typography>
                  <Box display={'flex'} alignItems={'center'}>
                    {cookie.mandatory && (
                      <Typography color="success.main" component="h2" variant="caption">
                        {t('ui.cookies.always_active')}
                      </Typography>
                    )}
                    <Switch
                      inputProps={{ 'aria-label': cookie.type }}
                      disabled={cookie.mandatory}
                      checked={cookie.accepted}
                      onClick={() => {
                        setCookiesSettings((previous) =>
                          previous.map((item) => {
                            if (!item.mandatory && item.type === cookie.type) {
                              return { ...item, accepted: !item.accepted }
                            }
                            return item
                          }),
                        )
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          <Grid container spacing={1} marginTop={0.5}>
            <Grid item flex={1}>
              <Button fullWidth variant="contained" onClick={() => onButtonClick(true)}>
                {t('ui.cookies.accept_all')}
              </Button>
            </Grid>
            <Grid item flex={1}>
              <Button fullWidth variant="outlined" onClick={() => onButtonClick(false)}>
                {t('ui.cookies.accept_required')}
              </Button>
            </Grid>
            <Grid item flex={1}>
              {!showSettings && (
                <Button fullWidth variant="outlined" onClick={() => setShowSettings(true)}>
                  {t('ui.cookies.view_settings')}
                </Button>
              )}
              {showSettings && (
                <Button fullWidth variant="outlined" onClick={onSaveSettings}>
                  {t('ui.cookies.save_settings')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </Fade>
  )
}

export default CookieConsentFooter
