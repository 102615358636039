const myProfile = {
  calendar: 'Calendar',
  chat: 'Chat',
  clients: 'Clients',
  dashboard: 'Dashboard',
  favorites: 'Favorites',
  posts: 'Posts',
  account: 'Profile',
}

export default myProfile
