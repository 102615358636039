import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import data from '~/_mock/testimonials'
import Card from '~/components/Card'
import Col from '~/components/UI/Grid/Col'
import Row from '~/components/UI/Grid/Row'
import Section from '~/components/UI/Section'
import { ColGrid } from './Testimonials.styled'

const HowItWorks = () => {
  const { t } = useTranslation()

  return (
    <Section size="small" padding={[{ target: 'y', value: 'normal' }]}>
      <Row>
        <Col xs={12}>
          <Typography variant="h3" component="h3" marginBottom={4}>
            {t('ui.testimonials.title')}
          </Typography>

          <Typography variant="body1" component="p" marginBottom={4}>
            {t('ui.testimonials.description')}
          </Typography>
        </Col>

        <ColGrid count={data?.length} xs={12}>
          {data?.map(({ id, ...data }) => (
            <Card key={id} elevation={1} {...data} sx={{ borderRadius: 0 }} />
          ))}
        </ColGrid>
      </Row>
    </Section>
  )
}

export default HowItWorks
