import GoogleIcon from '@mui/icons-material/Google'
import { Button, SxProps, Theme } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { google } from '~/helpers/utils/google'

const SignIn = ({ ...props }: { sx?: SxProps<Theme> }) => {
  const { t } = useTranslation()
  const openGoogleLoginPage = useCallback(() => {
    window.location.href = google.getGoogleUrl()
  }, [])

  return (
    <Button onClick={openGoogleLoginPage} startIcon={<GoogleIcon />} variant="outlined" {...props}>
      {t('ui.button.signInWithGoogle')}
    </Button>
  )
}

export default SignIn
