import SearchIcon from '@mui/icons-material/Search'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabelProps,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import data from '~/_mock/images'
import Carousel from '~/components/Carousel'
import Image from '~/components/Image'
import Section from '~/components/UI/Section'
import {
  SubcategoryMatches,
  useGetSuggestionsQuery,
} from '~/redux/features/api/search-bar-api-slice'
import useMapLocations, { MapLocation } from '../../hooks/useSearchMapLocations'

interface Suggestion {
  name: string
  type: 'Practitioner Name' | 'Category' | 'Subcategory'
  id: number
}

interface Coordinates {
  latitude: number
  longitude: number
}

const HeroCarousel: FC = () => {
  // categories practitioner
  const [query, setQuery] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<Suggestion>()
  const [options, setOptions] = useState<Suggestion[]>([])
  const { data: suggestions, isLoading } = useGetSuggestionsQuery(
    { query: query },
    { skip: query === '' },
  )

  useEffect(() => {
    if (suggestions) {
      const groupedOptions: Suggestion[] = []
      suggestions.user_matches.forEach((item: any) =>
        groupedOptions.push({ name: item.title, type: 'Practitioner Name', id: item.id, ...item }),
      )
      suggestions.category_matches.forEach((item: any) =>
        groupedOptions.push({ name: item.title, type: 'Category', id: item.id, ...item }),
      )
      suggestions.subcategory_matches.forEach((item: any) =>
        groupedOptions.push({ name: item.title, type: 'Subcategory', id: item.id, ...item }),
      )
      setOptions(groupedOptions)
    }
  }, [suggestions])

  const handleQueryChange = (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (value.length < 1 || value === null) setOptions([])
    if (reason !== 'reset') setQuery(value)
  }

  const handleSuggestionOptionChange = (
    event: SyntheticEvent<Element, Event>,
    value: string | Suggestion | null,
  ) => {
    setSelectedOption(value as Suggestion)
    setOptions([value as Suggestion])
  }

  // location dropdown
  const [coordinates, setCoordinates] = useState<Coordinates>()
  const [address, setAdress] = useState<string>()

  const onAddressChange = (address: string) => {
    setAdress(address)
  }

  const { locations, setLocations, setSearch, mapLocation, setMapLocation, loading } =
    useMapLocations({
      onAddressChange,
    })

  const onCoordinatesChange = (latitude: number, longitude: number) => {
    setCoordinates({ latitude, longitude })
  }

  const handleSearchInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: string,
  ) => {
    if (value === null || reason !== 'input') return
    setSearch(value)
  }

  const handleOptionChange = (event: SyntheticEvent, value: MapLocation | null) => {
    if (value === null) return

    const { latitude, longitude, address } = value
    setMapLocation((prev) => ({ ...prev, latitude, longitude, address }))

    onCoordinatesChange(latitude, longitude)
    onAddressChange(address as string)
    setLocations(locations.filter((location) => location.address === address))
  }

  // submit the search
  const navigate = useNavigate()

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const queryParams: { [key: string]: string } = {}

    if (selectedOption) {
      if (selectedOption.type.toLowerCase() === 'category')
        queryParams[selectedOption.type.toLowerCase()] = String(selectedOption.id)
      else if (selectedOption.type.toLowerCase() === 'subcategory') {
        queryParams['category'] = String((selectedOption as SubcategoryMatches).category_id)
        queryParams[selectedOption.type.toLowerCase()] = String(selectedOption.id)
      } else {
        queryParams['practitioner'] = String(selectedOption.id)
      }
    }

    if (coordinates) {
      queryParams['latitude'] = String(coordinates.latitude)
      queryParams['longitude'] = String(coordinates.longitude)
    }

    const queryString = new URLSearchParams(queryParams).toString()
    navigate(`/posts?${queryString}`)
  }
  return (
    <Section
      size="big"
      padding={false}
      innerBoxProps={{
        sx: { '--gutter': '0 !important' },
      }}
    >
      <Carousel>
        {data?.map(({ id, alt_description, urls }) => (
          <Carousel.Item key={id}>
            <Image alt={alt_description} src={urls.regular} />
          </Carousel.Item>
        ))}
      </Carousel>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          translate: '-50% -50%',
          zIndex: 2,
        }}
      >
        <Box position="relative" sx={{ pointerEvents: 'none' }}>
          <Typography
            gutterBottom
            variant="h2"
            component="h1"
            color="common.white"
            textAlign="center"
            sx={{ textShadow: (theme) => `0 1px 2px ${theme.palette.common.black}` }}
          >
            Click, Connect, Solved!
          </Typography>
        </Box>
        <FormControl fullWidth component="form" onSubmit={handleSearch}>
          <Paper
            elevation={2}
            sx={{
              padding: (theme) => `${theme.spacing(2)} ${theme.spacing(3)}`,
              display: 'flex',
              alignItems: 'center',
              width: '800px',
              gap: 2,
            }}
          >
            <Autocomplete
              options={options}
              groupBy={(option) => option.type}
              getOptionLabel={(option) => (option as Suggestion).name}
              onInputChange={_.debounce(handleQueryChange, 300)}
              onChange={handleSuggestionOptionChange}
              loading={isLoading}
              sx={{
                flex: 1,
                '& .MuiAutocomplete-popupIndicator': {
                  display: 'none',
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="standard"
                  placeholder="Search for a doctor, service or location"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    ...(params.InputLabelProps as Partial<InputLabelProps>),
                  }}
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <div style={{ fontWeight: 'bold', marginTop: 10, paddingLeft: 8 }}>
                    {params.group}
                  </div>
                  <ul style={{ padding: 0 }}>{params.children}</ul>
                </li>
              )}
            />
            <Autocomplete
              fullWidth
              disableClearable
              options={locations}
              getOptionLabel={(option) => option.address as string}
              onInputChange={handleSearchInputChange}
              onChange={handleOptionChange}
              loading={loading}
              value={mapLocation}
              sx={{ flex: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  variant="standard"
                  placeholder="Search for a location"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading && <CircularProgress color="inherit" size={20} />}
                      </React.Fragment>
                    ),
                  }}
                  InputLabelProps={{
                    ...(params.InputLabelProps as Partial<InputLabelProps>),
                  }}
                />
              )}
            />

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              sx={{ borderRadius: 10 }}
            >
              <SearchIcon />
            </Button>
          </Paper>
        </FormControl>
      </Box>
    </Section>
  )
}

export default HeroCarousel
