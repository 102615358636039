import { styled } from '@mui/material'
import { drawerWidth } from '~/components/NavigationDrawer/constant'

export const Container = styled('main', {
  shouldForwardProp: (prop: string) => !['drawer'].includes(prop),
})<{ drawer?: boolean }>(({ theme, drawer }) => {
  return {
    '--toolbar': '56px',
    minHeight: `calc(100dvh - var(--toolbar))`,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.up('sm')]: {
      '--toolbar': drawerWidth.permanent,
    },

    ...(drawer && {
      paddingLeft: drawerWidth.permanent,

      '&.animation': {
        '&-enter': {
          paddingLeft: drawerWidth.permanent,

          '&-active': {
            paddingLeft: drawerWidth.open,
            transition: theme.transitions.create('padding-left', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        },

        '&-exit': {
          paddingLeft: drawerWidth.open,

          '&-active': {
            paddingLeft: drawerWidth.permanent,
            transition: theme.transitions.create('padding-left', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          },
        },
      },
    }),
  }
})
