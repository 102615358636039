import axios from 'axios'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'

export const OPEN_STREET_MAP_URL = 'https://nominatim.openstreetmap.org'

const TRANSITION_DURATION_SECONDS = 0.635
const MIN_SEARCH_LENGTH = 3
const DEFAULT_LOCATION = {
  latitude: 49.6116,
  longitude: 6.1319,
}

interface useSearchMapLocationsProps {
  startingLocation?: MapLocation
  onAddressChange: (address: string) => void
}

export interface MapLocation {
  key?: number
  address: string
  latitude: number
  longitude: number
}

const useSearchMapLocations = ({
  startingLocation,
  onAddressChange,
}: useSearchMapLocationsProps) => {
  const {
    latitude: startingLatitude = DEFAULT_LOCATION.latitude,
    longitude: startingLongitude = DEFAULT_LOCATION.longitude,
    address: startingAddress = '',
  } = startingLocation || {}

  const ref = useRef<L.Map | null>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(startingAddress)
  const [locations, setLocations] = useState<MapLocation[]>([])
  const [mapLocation, setMapLocation] = useState<MapLocation>({
    address: startingAddress,
    latitude: startingLatitude,
    longitude: startingLongitude,
  })

  useEffect(() => {
    const updateMapCenterAndZoom = (latiture = startingLatitude, longitude = startingLongitude) => {
      const map = ref.current
      if (map) {
        map.flyTo([latiture, longitude], undefined, {
          animate: true,
          duration: TRANSITION_DURATION_SECONDS,
        })
      }
    }

    updateMapCenterAndZoom(mapLocation.latitude, mapLocation.longitude)
  }, [mapLocation.latitude, mapLocation.longitude, startingLatitude, startingLongitude])

  useEffect(() => {
    if (search === undefined || search === startingAddress || search.length < MIN_SEARCH_LENGTH) return void 0

    const debouncedSearch = _.debounce(async (searchTerm: string) => {
      try {
        const response = await axios.get(`${OPEN_STREET_MAP_URL}/search`, {
          params: {
            q: searchTerm.trim(),
            format: 'json',
          },
        })

        const data = response.data
        setLocations(
          data.reduce((acc: any[], item: any) => {
            if (item.display_name.toLowerCase().includes('luxembourg')) {
              acc.push({
                key: item.place_id,
                address: item.display_name,
                latitude: parseFloat(item.lat),
                longitude: parseFloat(item.lon),
              })
            }
            return acc
          }, []),
        )

        setLoading(false)
        return data
      } catch (error) {
        setLocations([])
        console.error('Error fetching data:', error)

        setLoading(false)
        return null
      }
    }, 500)

    setLoading(true)
    debouncedSearch(search)

    return () => {
      debouncedSearch.cancel()
    }
  }, [search, startingAddress])

  const getAddressFromCoordinates = ({
    latitude,
    longitude,
  }: {
    latitude: number
    longitude: number
  }) => {
    setLoading(true)
    axios
      .get(`${OPEN_STREET_MAP_URL}/reverse`, {
        params: {
          lat: latitude,
          lon: longitude,
          format: 'json',
        },
      })
      .then((response) => {
        const { error, address } = response.data

        if (!!error) {
          console.error(error)

          return
        }

        if (!!address) {
          const { city, neighbourhood, village, road, country } = address

          let builtAddress = country

          if (!!city) {
            builtAddress = `${city}, ${builtAddress}`
          } else if (!!neighbourhood) {
            builtAddress = `${neighbourhood}, ${builtAddress}`
          } else if (!!village) {
            builtAddress = `${village}, ${builtAddress}`
          }

          if (!!road) builtAddress = `${road}, ${builtAddress}`

          setSearch(builtAddress)
          setMapLocation((prev) => ({ ...prev, address: builtAddress }))

          // parent
          onAddressChange(builtAddress)
        }
      })
      .catch((error) => {
        console.error(error)
        const ERROR_MESSAGE = 'Error getting address from coordinates'
        setSearch('???')
        onAddressChange('???')

        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    ref,
    loading,
    setSearch,
    locations,
    setLocations,
    mapLocation,
    setMapLocation,
    getAddressFromCoordinates,
    startingLatitude: startingLatitude,
    startingLongitude: startingLongitude,
  }
}

export default useSearchMapLocations
