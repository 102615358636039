import GivenCalendar from '~/components/Management/Calendar/Calendar'
import Section from '~/components/UI/Section'
import { useGetBlockedProviderSlotsQuery } from '~/redux/features/api/booking-api-slice'

const getMonthDateRange = () => {
  const date = new Date() // Gets today's date
  const firstDay = new Date(date.getFullYear() - 1, date.getMonth(), 1) // First day of current month
  const lastDay = new Date(date.getFullYear() + 1, date.getMonth() + 1, 0) // Last day of current month

  // Format the dates as 'YYYY-MM-DD' if needed (depends on your API requirements)
  const formatDate = (date: Date) => date.toISOString().split('T')[0]

  return {
    startDate: formatDate(firstDay),
    endDate: formatDate(lastDay),
  }
}

const CalendarPage = () => {
  // Backend call to get the calendar information
  const { data: events, isLoading, error } = useGetBlockedProviderSlotsQuery(getMonthDateRange())

  if (isLoading) return <p>Loading...</p>
  if (error) return <p>Error loading events!</p>

  return (
    <Section
      size="big"
      padding={[
        { target: 'top', value: 'normal' },
        { target: 'bottom', value: 'none' },
      ]}
    >
      <GivenCalendar events={events} />
    </Section>
  )
}

export default CalendarPage
