import { urls } from '~/helpers/utils/urls'
import { apiSlice } from '../api/api-slice'

interface CategoryBase {
  id: number
  title: string
}

interface FetchedCategory extends CategoryBase {
  subcategories: CategoryBase[]
}

export interface SubCategory extends CategoryBase {
  category: CategoryBase
}

export interface Category extends CategoryBase {
  subcategories: SubCategory[]
}

interface CategoriesWithSubCategoriesProps {
  practitioner?: number
}

export const categoriesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Categories'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCategoriesWithSubCategories: builder.query<
        Category[],
        CategoriesWithSubCategoriesProps | void
      >({
        query: (args) => {
          return `/category/categories-with-subcategories${urls.argsToParams({ ...args })}`
        },
        transformResponse: (response: FetchedCategory[]) => {
          const transformedData: Category[] = response.map((category) => ({
            id: category.id,
            title: category.title,
            subcategories: category.subcategories.map((subcategory) => ({
              ...subcategory,
              category: {
                id: category.id,
                title: category.title,
              },
            })),
          }))
          return transformedData
        },
      }),
    }),
  })

export const { useGetCategoriesWithSubCategoriesQuery } = categoriesApiSlice
