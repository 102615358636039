import RequestLink from '~/pages/account/activation/RequestLink'
import ProfilePage from '~/pages/account/Profile'

export const accountRoutes = [
  {
    path: 'account',
    children: [
      { index: true, element: <ProfilePage /> },
      { path: 'request-link', element: <RequestLink /> },
    ],
  },
]
