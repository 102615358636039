import { styled } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { useNavigationDrawer } from '~/providers/NavigationDrawer'
import { drawerWidth } from './constant'

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'isProtectedPage',
})<{ isProtectedPage?: boolean }>(({ theme, isProtectedPage = false }) => {
  const { open } = useNavigationDrawer()

  const width = open ? drawerWidth.open : isProtectedPage ? drawerWidth.permanent : 0

  return {
    width,
    flexShrink: 0,
    whiteSpace: 'nowrap',

    '& .MuiDrawer-paper': {
      width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
  }
})
