import { FC } from 'react'
import Carousel from '~/components/Carousel'
import { CarouselProps } from '~/components/Carousel/Carousel.types'
import Image, { ImageProps } from '~/components/Image'
import { Prettify } from '~/helpers/types/app'
import exists from '~/helpers/utils/exists'

export type MediaImageProps = Prettify<
  {
    item?: ImageProps[]
    type: 'image'
    duration?: number
  } & CarouselProps
>

const MediaImage: FC<MediaImageProps> = ({ duration, item, sx, ...carousel }) => {
  if (item && item?.length > 1) {
    return (
      <Carousel {...carousel}>
        {item?.map(({ alt, src }) => (
          <Carousel.Item key={src}>
            <Image src={src} alt={alt || ''} sx={{ borderRadius: 1 }} />
          </Carousel.Item>
        ))}
      </Carousel>
    )
  }

  return (
    <>
      {exists(item) &&
        item?.map(({ alt, src }) => <Image key={src} src={src} alt={alt || ''} sx={{ ...sx }} />)}
    </>
  )
}

export default MediaImage
