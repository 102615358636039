import { styled } from '@mui/material/styles'
import { default as Grid } from '@mui/material/Unstable_Grid2'
import Col from '~/components/UI/Grid/Col'

export const OuterBox = styled(Grid, {
  target: 'outerBox',
})(({ theme, ...props }) => {
  return {
    backgroundColor: theme.palette.background.default,
  }
})

export const InnerBox = styled(Col, {
  target: 'innerBox',
})(({ theme, ...props }) => {
  return {
    backgroundColor: theme.palette.background.default,
  }
})
