import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { CardAction, Card as GuiCard } from './Card.styled'
import { CardProps } from './Card.types'

const Card: FC<CardProps> = ({ media, title, description, action, ...props }) => (
  <>
    {(media?.src || title || description) && (
      <GuiCard {...props}>
        <CardAction disableRipple disableTouchRipple {...action}>
          {media?.src && <CardMedia component="img" {...media} />}

          {(title || description) && (
            <CardContent sx={{ width: 'stretch' }}>
              {title && (
                <Typography gutterBottom variant="h6">
                  {title}
                </Typography>
              )}

              {description && (
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              )}
            </CardContent>
          )}
        </CardAction>
      </GuiCard>
    )}
  </>
)

export default Card
