import { zodResolver } from '@hookform/resolvers/zod'
import { AlertProps, Box, Button, FormControl, Typography } from '@mui/material'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import FormField from '~/components/Form/FormField'
import Col from '~/components/UI/Grid/Col'
import Row from '~/components/UI/Grid/Row'
import { useNotification } from '~/components/UI/NotificationProvider'
import Section from '~/components/UI/Section'
import { NEWSLETTER } from '~/constants/services'
import { Prettify } from '~/helpers/types/app'
import apiService from '~/services/api'

const schema = z.object({
  email: z.string().email('Invalid email address'),
})

type FormData = z.infer<typeof schema>

type Severity = Prettify<AlertProps['severity']>

const FormNewsLetter: FC = () => {
  const { t } = useTranslation()
  const { showNotification } = useNotification()

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const handleOpen = (severity: Severity, message: string) => {
    showNotification(message, severity)
  }

  const onSubmit = async (data: FormData) => {
    try {
      const response = await apiService.req(NEWSLETTER.method.POST, NEWSLETTER.url, null, data)
      // You can add additional logic here, such as displaying a success message

      // if email already exists, show Toast with information
      if (response.data.exists) {
        handleOpen('warning', t('ui.newsletter.exists'))
        return
      }

      // Show Toast with error message
      if (response.data.error) {
        handleOpen('error', t('ui.newsletter.error'))
        return
      }

      console.log('Newsletter subscription successful:', response)
      handleOpen('success', t('ui.newsletter.success'))

      // Reset the form
      reset({ email: '' })
    } catch (error) {
      console.error('Newsletter subscription failed:', error)
      handleOpen('error', t('ui.newsletter.error'))
    }
  }

  return (
    <Section size="small">
      <Row>
        <Col xs={12}>
          <Typography variant="h3">{t('ui.newsletter.title')}</Typography>
        </Col>

        <Col xs={12} md={6} mdOffset={3} mt={(theme) => theme.spacing(49 / 8)}>
          <Box
            noValidate
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', gap: (theme) => theme.spacing(2) }}
          >
            <FormField
              name="email"
              label={t('ui.form.email')}
              register={register}
              errors={errors}
              required
              sx={{ flexGrow: 1 }}
            />

            <FormControl>
              <Button type="submit" variant="contained" sx={{ flexGrow: 0 }}>
                {t('ui.submit')}
              </Button>
            </FormControl>
          </Box>
        </Col>
      </Row>
    </Section>
  )
}

export default FormNewsLetter
