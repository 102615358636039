import en from './en'
import fr from './fr'

export const DEFAULT_LANG = 'en' as const

const lng = [
  {
    slug: '/en',
    label: 'ui.locale.english',
    code: 'en',
    countryCode: 'EN',
    emoji: '🇬🇧',
    translation: en,
  },
  {
    slug: '/fr',
    label: 'ui.locale.french',
    code: 'fr',
    countryCode: 'FR',
    emoji: '🇫🇷',
    translation: fr,
  },
  {
    slug: '/pt',
    label: 'ui.locale.portuguese',
    code: 'pt',
    countryCode: 'PT',
    emoji: '🇵🇹',
    translation: {},
  },
] as const

export default lng
