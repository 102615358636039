import { createBrowserRouter, LoaderFunction } from 'react-router-dom'
import RootLayout from '~/components/Layout/root/RootLayout'
import lng from '~/locales'
import ErrorPage from '~/pages/Error'
import protectedRoutes from './protected'
import rootRoutes from './root'

type Language = (typeof lng)[number]['code']

const validateLanguage: LoaderFunction = ({ params, ...ctx }) => {
  const lang = params.lang as Language
  const locales = lng.map(({ code }) => code)

  if (!locales.includes(lang)) {
    throw new Response('Invalid language', { status: 404 })
  }

  return null
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      ...rootRoutes,
      {
        path: ':lang',
        loader: validateLanguage,
        children: [...rootRoutes],
      },
    ],
    errorElement: (
      <RootLayout>
        <ErrorPage />
      </RootLayout>
    ),
  },
  // protected routes
  {
    path: '/',
    element: <RootLayout drawer isProtected />,
    children: [
      ...protectedRoutes,
      {
        path: ':lang',
        loader: validateLanguage,
        children: [...protectedRoutes],
      },
    ],
    errorElement: (
      <RootLayout>
        <ErrorPage />
      </RootLayout>
    ),
  },
])

export default router
