import { GridProps } from '@mui/material/Grid'
import { default as Grid } from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

const RowStyled = styled(Grid)<Omit<GridProps, 'key'>>(({ theme }) => ({
  '--gutter': theme.spacing(-2),
  marginInline: 'var(--gutter)',

  [theme.breakpoints.up('md')]: {
    '--gutter': theme.spacing(-3),
  },
}))

const Row = forwardRef<HTMLDivElement, Omit<GridProps, 'key'>>(({ children, ...rest }, ref) => {
  return (
    <RowStyled ref={ref} container {...rest}>
      {children}
    </RowStyled>
  )
})
Row.displayName = 'Row'

export default Row
