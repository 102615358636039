const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT } = process.env

export const google = {
  getGoogleUrl: () => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth'

    const params = new URLSearchParams({
      response_type: 'code',
      client_id: REACT_APP_GOOGLE_CLIENT_ID!,
      redirect_uri: `${REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT}/google`,
      prompt: 'select_account',
      access_type: 'offline',
      scope: 'profile email',
    })

    return `${googleAuthUrl}?${params}`
  },
}
