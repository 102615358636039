import dayjs, { Dayjs } from "dayjs";

import { DateCalendar } from "@mui/x-date-pickers";
import React from "react";
import { Chip, Fab, Grid, Stack } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";

interface BookingCalendarProps {
  calendarStartingDate: Dayjs;
  calendarDisabled: boolean;
  onCalendarChange: (date: Dayjs) => void;
  slots: { startTime: Dayjs; occupied: boolean; client: number | null }[];
  selectedSlot: Dayjs | null;
  onSlotChange: (date: Dayjs | null) => void;
}

const BookingCalendar = ({
  calendarStartingDate,
  calendarDisabled,
  onCalendarChange,
  slots,
  selectedSlot,
  onSlotChange,
}: BookingCalendarProps) => {
  const accountDetail = useAppSelector((state) => state.auth.account_detail);
  console.log(accountDetail, slots);

  return (
    <React.Fragment>
      <DateCalendar
        defaultValue={calendarStartingDate}
        minDate={dayjs()}
        disabled={calendarDisabled}
        onChange={onCalendarChange}
      />

      <Grid container spacing={0.5}>
        {slots.map((slot: { startTime: Dayjs; occupied: boolean; client: number | null }, index: number) => (
          <Grid item>
            <Chip
              key={index}
              label={slot.startTime.format("HH:mm")}
              onClick={onSlotChange.bind(null, slot.startTime)}
              disabled={slot.occupied}
              color={
                selectedSlot?.isSame(slot.startTime) ? "primary" : slot.client === accountDetail.id ? "success" : "secondary"
              }
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default BookingCalendar;
