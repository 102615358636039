import { Box, Button, FormHelperText, InputLabel, TextField, Typography } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { TimeField } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import LoadingSpinner from '~/components/UI/LoadingSpinner'
import { Post } from '~/helpers/types/post'
import {
  PostFormDataValues,
  useUpdatePostMutation,
} from '~/redux/features/api/management-api-slice'
import LocationPicker from './LocationPicker'
import PostImageGalleryForm from './PostImageGalleryForm'

const MAX_IMAGES = 8

const schema = z.object({
  category: z.coerce.number(),
  title: z.string().min(1),
  description: z.string().min(1),
  price: z.coerce.number().min(1),
  duration: z.coerce.number().min(1),
  address: z.string().min(3),
  latitude: z.coerce.number(),
  longitude: z.coerce.number(),
  availableFromTime: z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date'),
  availableToTime: z.custom<Dayjs>((val) => val instanceof dayjs, 'Invalid date'),
})

const EditPostForm = ({
  post,
  // categories,
}: {
  post: Post
  // categories: Category[]
  // storeOptions: any[];
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const [images, setImages] = useState(
    Array.from(Array(MAX_IMAGES)).map<{ index: number; file?: File }>((_, index) => {
      return { index }
    }),
  )

  const {
    register,
    setValue,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<PostFormDataValues>({
    defaultValues: {
      // category: post.category,
      title: post.title,
      description: post.description,
      price: post.price,
      address: post.address,
      latitude: post.gps_lat,
      longitude: post.gps_lng,
      duration: post.duration,
      availableFromTime: dayjs(post.available_from_time, 'HH:mm:ss'),
      availableToTime: dayjs(post.available_to_time, 'HH:mm:ss'),
    },

    resolver: zodResolver(schema),
  })

  const [updateStorePost, { isLoading }] = useUpdatePostMutation()
  const [imageIndexesToDelete, setImageIndexesToDelete] = useState<number[]>([])

  const onSubmit = async (data: PostFormDataValues) => {
    const imagesToUpload = images.filter((image) => image.file)

    if (imagesToUpload.length === 0 && post.postimage_set?.length === 0) {
      setError('root.images', {
        type: 'manual',
        message: `You must select between 1 and ${MAX_IMAGES} images.`,
      })
      return
    }
    const formData = new FormData()
    formData.append('id', `${post.id}`)

    if (data.title !== post.title) formData.append('title', data.title)

    if (data.category !== post.subcategory) formData.append('category', `${data.category}`)

    if (data.description !== post.description) formData.append('description', data.description)

    if (data.address !== post.address) formData.append('address', data.address)

    if (data.latitude !== post.gps_lat) formData.append('gps_lat', `${data.latitude.toFixed(8)}`)

    if (data.longitude !== post.gps_lng) formData.append('gps_lng', `${data.longitude.toFixed(8)}`)

    if (data.price !== post.price) formData.append('price', `${data.price}`)

    if (data.duration !== post.duration) formData.append('duration', `${data.duration}`)

    formData.append('available_from_time', `${data.availableFromTime!.format('HH:mm')}`)
    formData.append('available_to_time', `${data.availableToTime!.format('HH:mm')}`)

    imagesToUpload.forEach((imageFile, index) => {
      formData.append(`images_to_upload`, imageFile.file!)
    })

    imageIndexesToDelete.forEach((imageId, index) => {
      formData.append(`images_to_delete`, `${imageId}`)
    })

    try {
      const updatedPost = await updateStorePost(formData).unwrap()

      navigate(`/management/posts`)
    } catch (err: any) {
      console.error(err)
      const { data: errorData }: any = err
      for (const property in errorData) {
        if (property === 'images_to_upload') {
          setError('root.images', {
            type: 'manual',
            message: errorData[property],
          })
        } else {
          setError(
            property === 'detail' ? 'root.detail' : (property as keyof PostFormDataValues),
            {
              type: 'manual',
              message: errorData[property],
            },
            { shouldFocus: property !== 'detail' },
          )
        }
      }
    }
  }
  // const { categoryItems } = useMemo(() => {
  //   return {
  //     categoryItems: categories
  //       .map((category) => {
  //         return {
  //           id: category.id,
  //           label: category.title,
  //         }
  //       })
  //       .map((option: any) => {
  //         return (
  //           <MenuItem key={option.id} value={option.id}>
  //             {option.label}
  //           </MenuItem>
  //         )
  //       }),
  //   }
  // }, [categories])

  const handleCoordinatesChange = (latitude: number, longitude: number) => {
    setValue('latitude', latitude)
    setValue('longitude', longitude)
  }

  const handleAddressChange = (address: string) => {
    setValue('address', address)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {isLoading && <LoadingSpinner />}

      <Typography component="h1" variant="h5" width="100%">
        Edit Product
      </Typography>

      <FormHelperText error={Boolean(errors.root?.detail)}>
        {(errors.root?.detail && errors.root?.detail.message) ||
          (errors.root?.detail && 'Problem creating Store!')}
      </FormHelperText>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1, width: '100%' }}
      >
        {/* {categories && (
          <FormControl fullWidth margin="dense">
            <InputLabel id={'Category'}>{'Category'}</InputLabel>
            <Controller
              name="category"
              control={control}
              defaultValue={post.category}
              rules={{
                required: 'Category is required',
              }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    fullWidth
                    labelId="category-label"
                    label="Category"
                    error={Boolean(errors.category)}
                    onChange={(event: SelectChangeEvent<number>, child: React.ReactNode) => {
                      field.onChange(event.target.value as number)
                    }}
                  >
                    {categoryItems}
                  </Select>
                )
              }}
            />

            <FormHelperText error={Boolean(errors['category'] && errors['category'].message)}>
              {(errors['category'] && errors['category'].message) ||
                (errors['category'] && `Invalid ${'category'}!`)}
            </FormHelperText>
          </FormControl>
        )} */}
        <TextField
          margin="dense"
          required
          fullWidth
          id="title"
          label="Title"
          autoComplete="title"
          {...register('title', { required: true })}
          error={Boolean(errors.title)}
          helperText={
            (errors.title && errors.title.message) || (errors.title && 'Title is required.')
          }
        />
        <TextField
          margin="dense"
          required
          fullWidth
          id="description"
          label="Description"
          multiline
          minRows={2}
          autoComplete="description"
          {...register('description', { required: true })}
          error={Boolean(errors.description)}
          helperText={
            (errors.description && errors.description.message) ||
            (errors.description && 'Description is required.')
          }
        />
        <InputLabel sx={{ paddingBottom: 1 }}>Images</InputLabel>
        <PostImageGalleryForm
          startingImages={post.postimage_set}
          images={images}
          resetImages={(imageId) => {
            if (!imageIndexesToDelete.includes(imageId)) {
              setImageIndexesToDelete((previous) => [...previous, imageId])
            }
          }}
          setImages={(index: number, file?: File) => {
            setImages((images) => {
              images[index] = { ...images[index], file: file }
              return images
            })
          }}
        />
        <FormHelperText error={Boolean(errors.root?.images)}>
          {(errors.root?.images && errors.root?.images.message) ||
            (errors.root?.images && 'Invalid images.')}
        </FormHelperText>

        <TextField
          type="hidden"
          {...register('address', { required: true })}
          sx={{ display: 'none' }}
        />

        <LocationPicker
          hasError={Boolean(errors.address)}
          errorMessage={errors.address?.message || 'Invalid Address'}
          startingLocation={{
            address: post.address,
            latitude: post.gps_lat,
            longitude: post.gps_lng,
          }}
          onCoordinatesChange={handleCoordinatesChange}
          onAddressChange={handleAddressChange}
        />

        <TextField
          margin="dense"
          required
          fullWidth
          id="price"
          label="Price"
          minRows={2}
          autoComplete="price"
          {...register('price', {
            required: true,
            valueAsNumber: true,
            validate: (value) => value > 0 || 'Must be positive!',
          })}
          error={Boolean(errors.price)}
          helperText={
            (errors.price && errors.price.message) || (errors.price && 'Price is required.')
          }
        />
        <TextField
          margin="dense"
          required
          fullWidth
          label="Duration"
          minRows={2}
          autoComplete="duration"
          {...register('duration', {
            required: true,
          })}
          error={Boolean(errors.price)}
          helperText={
            (errors.price && errors.price.message) || (errors.price && 'Price is required.')
          }
        />
        <Controller
          control={control}
          name="availableFromTime"
          rules={{ required: true }}
          render={({ field }) => {
            return <TimeField label="Available From" ampm={false} {...field} />
          }}
        />
        <Controller
          control={control}
          name="availableToTime"
          rules={{ required: true }}
          render={({ field }) => {
            return <TimeField label="Available To" ampm={false} {...field} />
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          {t('update')}
        </Button>
      </Box>
    </Box>
  )
}

export default EditPostForm
