import { AccessTime, SvgIconComponent } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import { Tooltip } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { map } from '~/helpers/utils/mappings'

interface IconPickerProps {
  status: keyof typeof iconsMap
}

const iconsMap: { [key: string]: SvgIconComponent } = {
  in_progress: MoreHoriz,
  completed: DoneAllIcon,
  canceled: CancelIcon,
  accepted: CheckCircleOutlineIcon,
}

const IconPicker: React.FC<IconPickerProps> = ({ status }) => {
  const Icon = iconsMap[status] // Determine which icon to use
  return <Icon />
}

const CalendarDialogContent = ({
  start,
  price,
  unit,
  location,
  status,
  clientName,
}: {
  start: Date
  price: number
  unit: string
  location: string
  status: string
  clientName: string
}) => {
  console.log(status)
  return (
    <List>
      <ListItem>
        <Tooltip title="Client" placement="top">
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={clientName} />
      </ListItem>
      <ListItem>
        <Tooltip title="Time" placement="top">
          <ListItemIcon>
            <AccessTime />
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={new Date(start).toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        />
      </ListItem>
      <ListItem>
        <Tooltip title="Price" placement="top">
          <ListItemIcon>
            <ShoppingCartOutlinedIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={`${map.currencyCodeToSymbol(unit)} ${price}`} />
      </ListItem>
      <ListItem>
        <Tooltip title="Address" placement="top">
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={location} />
      </ListItem>
      <ListItem>
        <Tooltip title="Status" placement="top">
          <ListItemIcon>{IconPicker({ status })}</ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={status
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
        />
      </ListItem>
    </List>
  )
}

export default CalendarDialogContent
