const t = {
  apply: 'Apply',
  back: 'Back',
  cancel: 'Cancel',
  close: 'Close',
  delete: 'Delete',
  edit: 'Edit',
  no: 'No',
  register: 'Register',
  reset: 'Reset',
  save: 'Save',
  send: 'Send',
  signIn: 'Sign In',
  signInWithGoogle: 'Sign In with Google',
  signOut: 'Sign Out',
  signUp: 'Sign Up',
  signUpWithGoogle: 'Sign Up with Google',
  submit: 'Submit',
  update: 'Update',
  view: 'View',
  yes: 'Yes',
}

export default t
