import about from './about'
import account from './account'
import contactUs from './contactUs'
import password from './password'

const page = {
  about,
  account,
  contactUs,
  password,
}

export default page
