import page from './page'
import ui from './ui'

const translation = {
  book_now: 'Book Now',
  i_accept: 'I accept',
  refuse: 'Refuse',
  select_language: 'Select language',
  create: 'create',
  update: 'update',
  delete: 'delete',
  user_type_normal: 'Client',
  user_type_store_owner: 'Services Provider',
  first_name: 'First Name',
  last_name: 'Last Name',
  email_address: 'Email Address',
  password: 'Password',
  'problem_on_{{value}}_message': 'Ups problem on {{value}}!',
  confirm_password: 'Confirm Password',
  '{{value}}_is_required': '{{value}} is required',
  practitioner_name: 'Practitioner name',
  availability: 'Availability',

  ui,
  page,
}

export default translation
