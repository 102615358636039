import LanguageIcon from '@mui/icons-material/Language'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import lng, { DEFAULT_LANG } from '~/locales'

type UseGetPath = (typeof lng)[number]['slug']
function getPath(locale: UseGetPath) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname, search } = useLocation()

  const newPath = pathname
    .replace('/' + i18n.language + '/', '/')
    .replace(/\/\//g, '/')
    .concat(search)

  const localeSlug = locale.replace('/', '')
  if (localeSlug === DEFAULT_LANG) {
    return newPath
  }

  return locale.concat(newPath)
}

const LanguageSelector: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t, i18n } = useTranslation()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (lng: string) => {
    setAnchorEl(null)

    typeof lng === 'string' && i18n.changeLanguage(lng?.toLowerCase())
  }

  return (
    <>
      <IconButton
        size="large"
        aria-label="language selector menu"
        aria-controls="language-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <LanguageIcon />
      </IconButton>

      <Menu
        id="language-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {lng.map(({ countryCode, slug, label, emoji, ...l }) => (
          <MenuItem
            key={countryCode}
            disabled={i18n.language.toLowerCase() === countryCode}
            onClick={() => handleClose(countryCode)}
            component={Link}
            to={getPath(slug)}
          >
            {emoji} {t(label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSelector
