import { CalendarMonth, List, Speed, StarOutline } from '@mui/icons-material'

const myProfile = [
  { to: '/account', label: 'ui.myProfile.account', icon: Speed },
  { to: '/management/favorites', label: 'ui.myProfile.favorites', icon: StarOutline },
  { to: '/management/calendar', label: 'ui.myProfile.calendar', icon: CalendarMonth },
  { to: '/management/posts', label: 'ui.myProfile.posts', icon: List },
] as const

export default myProfile
