import { Category } from '~/helpers/types/category'
import Page from '~/pages/posts'
import Detail from '~/pages/posts/Detail'

export type GetPosts = {
  count?: number
  next?: string
  previous?: string
  results?: Category[]
}

export const postRoutes = [
  {
    path: 'posts',
    children: [
      { index: true, element: <Page /> },
      { path: ':id', element: <Detail /> },
    ],
  },
]
