import { Container } from '@mui/material'
// import { useListStoresQuery } from "../../../redux/features/api/management-api-slice";

const NewPostPage = () => {
  // const categories = useAppSelector((state) => state.categories.categories);
  // const storesQuery = useListStoresQuery({});

  // const isLoading = storesQuery.isLoading;
  // const isSuccess = !isLoading && storesQuery.isSuccess;
  // const isError = storesQuery.isError;

  // let storeOptions: any[] = [];

  // if (isSuccess && storesQuery.data && storesQuery.data.results) {
  //   storeOptions = forms.getNewPostStoreOptions(storesQuery.data.results);
  // }

  return (
    <Container>
      {/* <PostForm categories={categories} /> */}
      {/* {isLoading && <LoadingSpinner />} */}
      {/* {isSuccess && (
        <PostForm storeOptions={storeOptions} categories={categories} />
      )} */}
      {/* {isError && <h1>Error</h1>} */}
    </Container>
  )
}

export default NewPostPage
