import { Backdrop } from '@mui/material'
import LoadingSpinner from './LoadingSpinner'

const FullScreenOverlay = ({
  Component = <LoadingSpinner color="primary" size={60} />,
}: {
  Component?: JSX.Element
}) => {
  return (
    <Backdrop sx={{ color: (theme) => theme.palette.background.default, zIndex: 9999 }} open={true}>
      {Component}
    </Backdrop>
  )
}

export default FullScreenOverlay
