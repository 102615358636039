import { Middleware } from '@reduxjs/toolkit'
import { cacheCookies, clearCachedCookies } from './cookies-actions'
import { cookiesActions } from './cookies-slice'

const cookiesMiddleware: Middleware<{}, any> =
  ({ getState }) =>
  (next) =>
  (action: any) => {
    const result = next(action)

    if (action.type === cookiesActions.setCookies.type) {
      const { cookies } = action.payload
      cacheCookies({ cookies })
    } else if (action.type === cookiesActions.reset.type) {
      clearCachedCookies()
    }

    return result // Return the result of processing the action
  }

export default cookiesMiddleware
