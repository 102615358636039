import { UseZodRequestPasswordResetSchema } from '~/components/FormPasswordRequestReset/useZodPasswordRequestReset'
import { UseZodPasswordResetSchema } from '~/components/FormPasswordReset/useZodPasswordReset'
import { apiSlice } from './api-slice'

export const accountApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['AccountDetail'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      requestPasswordReset: builder.mutation<void, UseZodRequestPasswordResetSchema>({
        query: (data) => ({
          url: '/password/request-reset/',
          method: 'POST',
          body: { ...data },
        }),
      }),
      setPassword: builder.mutation<void, UseZodPasswordResetSchema>({
        query: (data) => ({
          url: '/password/set-password/',
          method: 'POST',
          body: { ...data },
        }),
      }),
    }),
  })

export const { useRequestPasswordResetMutation, useSetPasswordMutation } = accountApiSlice
