import { Button, FormControl } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Form, { FormField } from '~/components/Form'
import GroupFormField from '~/components/Form/GroupFormField'
import { useNotification } from '~/components/UI/NotificationProvider'
import { useSetPasswordMutation } from '~/redux/features/api/password-api-slice'
import { useZodPasswordReset, UseZodPasswordResetSchema } from './useZodPasswordReset'

const FormPasswordReset = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showNotification } = useNotification()

  // Define your form schema using zod and translations
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useZodPasswordReset({ password: '', password2: '' })
  const [resetPassword, { isLoading, isError, isSuccess }] = useSetPasswordMutation()

  function onError(msg: string) {
    showNotification(msg, 'error')
  }

  const onSubmit: SubmitHandler<UseZodPasswordResetSchema> = async (data) => {
    try {
      const response = await resetPassword(data).unwrap()
      console.log('\n=== response ===')
      console.log(response)
      console.log('===\n')

      if (isError) {
        onError(t('ui.password_reset_error_message'))
        return void 0
      }

      if (isSuccess && !isLoading) {
        showNotification(t('ui.password_reset_success_message'), 'success')

        setTimeout(() => navigate('/login'), 15000)
        return void 0
      }
    } catch (err: any) {
      onError(t('ui.api.something_went_wrong'))
    }
  }

  return (
    <Form noValidate size="small" onSubmit={handleSubmit(onSubmit)} register={register}>
      <GroupFormField>
        <FormField
          required
          label={t('ui.form.password')}
          type="password"
          name="password"
          errors={errors}
          register={register}
        />

        <FormField
          required
          label={t('ui.form.confirm_password')}
          type="password"
          name="password2"
          errors={errors}
          register={register}
        />
      </GroupFormField>

      <FormControl sx={{ mt: 2 }}>
        <Button type="submit" variant="contained">
          {t('ui.button.reset')}
        </Button>
      </FormControl>
    </Form>
  )
}

export default FormPasswordReset
