import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'
import { brand } from '~/config/brand-config'

const Footer: FC<{}> = () => (
  <Section
    outerBoxProps={{
      component: 'footer',
      sx: { backgroundColor: 'secondary.main' },
    }}
    innerBoxProps={{ sx: { backgroundColor: 'transparent' } }}
  >
    <Row>
      <Col xs={12} sm={6} md={6}>
        <Typography variant="h6" color="secondary.contrastText">
          {brand.name}
        </Typography>
        <Typography variant="subtitle1" color="secondary.contrastText" paragraph>
          Empowering individuals and businesses to offer services in their local communities with
          our user-friendly and affordable platform.
        </Typography>
      </Col>
      <Col xs={12} sm={5} md={5}>
        <Typography variant="h6" color="secondary.contrastText">
          Contact Us
        </Typography>
      </Col>
    </Row>
  </Section>
)

export default Footer
