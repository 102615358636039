const t = {
  error: {
    title: "Échec de la vérification de l'e-mail",
    subtitle:
      "Malheureusement, nous n'avons pas pu vérifier votre adresse e-mail. Cela pourrait être dû à un lien expiré ou à un problème avec notre système",
    content: '',
    buttonGroup: [
      {
        id: 'vb4h53gref',
        label: 'Renvoyer le lien de vérification',
        href: '/account/request-link',
        variant: 'outlined',
        color: 'primary',
      },
      {
        id: 'ojf21vbb20',
        label: 'Contacter le support',
        href: '/contact-us',
        variant: 'outlined',
        color: 'primary',
      },
    ],
  },
  success: {
    title: 'Bienvenue chez Given Health.',
    subtitle: '',
    content:
      'E-mail vérifié ! Commencez à utiliser nos services en vous connectant à votre compte.',
    buttonGroup: [
      {
        id: 'ojf21vbb20',
        label: 'Connectez-vous à votre compte',
        href: '/login',
        variant: 'contained',
        color: 'primary',
      },
    ],
  },

  error_message: "L'activation a échoué.",
  success_message: 'Votre compte a été activé avec succès',
  api_error_message: "Une erreur s'est produite. Veuillez réessayer plus tard.",
}
export default t
