import type { SectionProps } from '../Section.types'

const sectionSpacing = {
  top: {
    none: { paddingTop: 0 },
    small: { paddingTop: { xs: 2, md: 4, xl: 5, xxl: 7 } },
    normal: { paddingTop: { xs: 4, md: 6, xl: 8, xxl: 10 } },
    big: { paddingTop: { xs: 6, md: 8, lg: 10, xl: 12, xxl: 14 } },
  },
  bottom: {
    none: { paddingBottom: 0 },
    small: { paddingBottom: { xs: 2, md: 4, xl: 5, xxl: 7 } },
    normal: { paddingBottom: { xs: 4, md: 6, xl: 8, xxl: 10 } },
    big: { paddingBottom: { xs: 6, md: 8, lg: 10, xl: 12, xxl: 14 } },
  },
  y: {
    none: { paddingBlock: 0 },
    small: { paddingBlock: { xs: 2, md: 4, xl: 5, xxl: 7 } },
    normal: { paddingBlock: { xs: 4, md: 6, xl: 8, xxl: 10 } },
    big: { paddingBlock: { xs: 6, md: 8, lg: 10, xl: 12, xxl: 14 } },
  },
} as const

type GetSectionPaddingProps = SectionProps['padding']
export const getSectionPadding = (padding: GetSectionPaddingProps) => {
  if (typeof padding === 'boolean' && !padding) {
    // Return the requested object when padding is a string
    return void 0
  }

  const padLgn = padding?.length

  // Initialize the result object
  const result = padding?.map(({ target, value }, idx) => {
    if (padLgn === 2 && idx === 0) target = 'top'
    return sectionSpacing[target][value]
  })

  // Merge the objects into a single object
  return result?.reduce((acc, obj) => ({ ...acc, ...obj }), {})
}
