// GlobalStyles.tsx
import { default as MuiGlobalStyles } from '@mui/material/GlobalStyles'

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={(theme) => {
        return {
          body: {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            lineHeight: 1.18,
            margin: 0,
            MozOsxFontSmoothing: 'grayscale',
            padding: 0,
            scrollBehavior: 'smooth',
            WebkitFontSmoothing: 'antialiased',
          },

          'input, button, select, optgroup, textarea': {
            margin: 0,
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            outline: 'none',
            border: 'none',
          },
        }
      }}
    />
  )
}

export default GlobalStyles
