import axios from 'axios'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingSpinner from '~/components/UI/LoadingSpinner'
import { useNotification } from '~/components/UI/NotificationProvider'
import Section from '~/components/UI/Section'
import { brand } from '~/config/brand-config'
import { authActions } from '~/redux/features/auth/auth-slice'
import { cookiesActions } from '~/redux/features/cookies/cookies-slice'
import { navigationActions } from '~/redux/features/navigation/navigation-slice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'

const SocialAuthPage = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { showNotification } = useNotification()

  const intendedRoute = useAppSelector((state) => state.navigation.intendedRoute)

  useEffect(() => {
    const handleGoogleAuth = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/auth/google/${location.search}`
        const response = await axios.get(apiUrl)
        const tokensData = response.data

        if (!tokensData?.access) {
          console.error('Invalid response from Google auth')

          showNotification('Authentication failed. Please try again or contact support.', 'error')

          navigate('/login')
        }

        dispatch(authActions.setCredentials({ ...tokensData }))

        dispatch(
          cookiesActions.setCookies({
            cookies: tokensData.account_detail?.settings?.cookie_set ?? [],
          }),
        )

        showNotification(`Welcome to ${brand.name}!`, 'success')

        navigate(intendedRoute || '/')

        dispatch(navigationActions.setIntendedRoute(null))
      } catch (error) {
        console.error('Error during Google auth:', error)

        showNotification('Authentication failed. Please try again or contact support.', 'error')

        navigate('/login')
      }
    }

    handleGoogleAuth()
  }, [])

  return (
    <Section
      padding={[{ target: 'y', value: 'big' }]}
      innerBoxProps={{ sx: { display: 'flex', justifyContent: 'center' } }}
    >
      <LoadingSpinner />
    </Section>
  )
}

export default SocialAuthPage
