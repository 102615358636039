import { Typography } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import FormPasswordRequestReset from '~/components/FormPasswordRequestReset'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'

const PasswordReset = () => {
  const { i18n } = useTranslation()

  const tPage = useMemo(() => {
    const data = i18n.store.data
    const lang = i18n.language
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const page = data[lang].translation?.page?.password?.requestReset

    return page
  }, [i18n])

  const { title, content } = tPage || {}

  return (
    <Section size="small">
      <Row>
        <Col xs={12}>
          <Typography variant="h3">{title}</Typography>

          <Typography variant="body1" mt={3}>
            {content}
          </Typography>
        </Col>
      </Row>

      <Row>
        <Col xs={12} mt={3}>
          <FormPasswordRequestReset />
        </Col>
      </Row>
    </Section>
  )
}
export default PasswordReset
