import About from '~/pages/about'
import Login from '~/pages/account/Login'
import Register from '~/pages/account/Register'
import SocialAuthPage from '~/pages/account/SocialAuth'
import ContactUs from '~/pages/contactUs'
import Home from '~/pages/home'

export const rootRoutes = [
  { index: true, element: <Home /> },
  { path: 'about', element: <About /> },
  { path: 'contact-us', element: <ContactUs /> },
  { path: 'login', element: <Login /> },
  { path: 'register', element: <Register /> },
  { path: 'google', element: <SocialAuthPage /> },
]
