import ValidationPage from '~/pages/account/activation/Validation'

export const accountRoutes = [
  {
    path: 'account',
    children: [
      {
        path: 'validation/:uuid/:token',
        element: <ValidationPage />,
      },
    ],
  },
]
