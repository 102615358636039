import { Typography } from '@mui/material'
import { useMemo } from 'react'
import FormActivationRequestLink from '~/components/FormActivationRequestLink'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'
import i18n from '~/i18n'

const RequestLink = () => {
  const tPage = useMemo(() => {
    const data = i18n.store.data
    const lang = i18n.language
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const page = data[lang].translation?.page?.account?.requestLink

    return page
  }, [i18n])

  const { title, subtitle } = tPage || {}

  return (
    <Section size="small">
      <Row>
        <Col xs={12}>
          <Typography variant="h4">{title}</Typography>

          <Typography variant="body1" mt={3}>
            {subtitle}
          </Typography>
        </Col>

        <Col xs={12} mt={3}>
          <FormActivationRequestLink />
        </Col>
      </Row>
    </Section>
  )
}
export default RequestLink
