import { type Crop } from "react-image-crop";
export const canvas = {
  setCanvasImage: (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: Crop
  ) => {
    if (!crop || !canvas || !image) {
      return null;
    }

    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const { x, y, width, height } = crop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      x * scaleX,
      y * scaleY,
      width * scaleX,
      height * scaleY,
      0,
      0,
      width * pixelRatio,
      height * pixelRatio
    );

    return canvas;
  },
  getFileFromCanvas: (canvas: HTMLCanvasElement) => {
    // Convert canvas content to Data URL
    const dataURL = canvas.toDataURL("image/jpeg"); // Change MIME type as needed

    // Convert Data URL to Blob
    const byteString = atob(dataURL.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" }); // Change MIME type as needed

    // Create a File object
    const fileName = "myImage.jpg"; // Set the desired file name
    return new File([blob], fileName, { type: "image/jpeg" });
  },
};
