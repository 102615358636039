const images = [
  {
    id: 'dqkjbr23oov',
    alt_description: 'Medical Administration Garden',
    urls: {
      regular:
        process.env.REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT +
        '/assets/Medical Administration Garden.jpg',
    },
  },
  {
    id: 'dqkjbr23oovv',
    alt_description: 'Green shirt white mask',
    urls: {
      regular:
        process.env.REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT + '/assets/Green shirt white mask.jpg',
    },
  },
  {
    id: 'pinf2inbwrnobr',
    alt_description: 'Smartwatch Healthcare',
    urls: {
      regular:
        process.env.REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT + '/assets/Smartwatch Healthcare.jpg',
    },
  },
  {
    id: 'vobu30bi3',
    alt_description: 'Medical Administration',
    urls: {
      regular:
        process.env.REACT_APP_GOGGLE_REDIRECT_URL_ENDPOINT + '/assets/Medical Administration.jpg',
    },
  },
] as const

export default images
