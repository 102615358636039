import Typography from '@mui/material/Typography'
import data from '~/_mock/howItWorks'
import Card from '~/components/Card'
import Col from '~/components/UI/Grid/Col'
import Row from '~/components/UI/Grid/Row'
import Section from '~/components/UI/Section'
import { ColGrid } from './HowItWorks.styled'

const HowItWorks = () => {
  return (
    <Section size="small" padding={[{ target: 'y', value: 'normal' }]}>
      <Row>
        <Col xs={12}>
          <Typography variant="h3" component="h3" marginBottom={4}>
            How It Works
          </Typography>
        </Col>

        <ColGrid count={data?.length} xs={12}>
          {data?.map(({ id, ...data }) => (
            <Card key={id} elevation={1} {...data} sx={{ borderRadius: 0 }} />
          ))}
        </ColGrid>
      </Row>
    </Section>
  )
}

export default HowItWorks
