import { AttachFile, Close } from '@mui/icons-material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Typography,
} from '@mui/material'
import { MuiFileInput } from 'mui-file-input'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Form, { FormField } from '~/components/Form'
import GroupFormField from '~/components/Form/GroupFormField'
import GoogleSignUp from '~/components/Google/SignUp'
import { Col, Row } from '~/components/UI/Grid'
import LoadingSpinner from '~/components/UI/LoadingSpinner'
import { useNotification } from '~/components/UI/NotificationProvider'
import { useRegisterMutation } from '~/redux/features/api/account-api-slice'
import { useZodRegister, UseZodRegisterSchema } from './useZodRegister'

const RegisterForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const [registerUser, { isLoading }] = useRegisterMutation()

  // Define your form schema using zod and translations
  const {
    control,
    handleSubmit,
    register,
    setError,
    watch,
    formState: { errors },
  } = useZodRegister()
  const accountType = watch('account_type')

  const isDoctor = 'doctor' === accountType

  const onSubmit: SubmitHandler<UseZodRegisterSchema> = async (data) => {
    try {
      const register = await registerUser(data).unwrap()
      console.log({ register })

      if (!register) {
        showNotification(t('ui.form.registration.error.message'), 'error')
      }

      if (!isLoading && register) {
        showNotification(t('ui.form.registration.success.message'), 'success')
        navigate('/login')
      }
    } catch (err: any) {
      console.error(err)

      showNotification(t('ui.form.registration.error.api_message'), 'error')

      const { data: errorData }: any = err

      for (const property in errorData) {
        setError(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          property === 'detail' ? 'root.detail' : property,
          {
            type: 'manual',
            message: errorData[property],
          },
          { shouldFocus: property !== 'detail' },
        )
      }
    }
  }

  return (
    <Row>
      <Col xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            {!isLoading && <LockOutlinedIcon />}
            {isLoading && <LoadingSpinner />}
          </Avatar>

          <Typography component="h1" variant="h5">
            {t('ui.button.signUp')}
          </Typography>

          <FormHelperText
            error={Boolean(errors.root?.detail)}
            sx={{
              display: errors.root?.detail ? 'block' : 'none',
            }}
          >
            {(errors.root?.detail && errors.root?.detail.message) ||
              (errors.root?.detail &&
                t('problem_on_{{value}}_message', { value: t('ui.button.signUp') }))}
          </FormHelperText>
        </Box>
      </Col>

      <Col xs={12}>
        <Form noValidate size="small" onSubmit={handleSubmit(onSubmit)} register={register}>
          <FormField
            autoFocus
            required
            autoComplete="email"
            errors={errors}
            label={t('ui.form.email')}
            name="email"
            register={register}
          />

          <GroupFormField>
            <FormField
              required
              label={t('ui.form.first_name')}
              type="text"
              name="first_name"
              errors={errors}
              register={register}
            />

            <FormField
              required
              label={t('ui.form.last_name')}
              type="text"
              name="last_name"
              errors={errors}
              register={register}
            />
          </GroupFormField>

          <GroupFormField>
            <FormField
              required
              label={t('ui.form.password')}
              type="password"
              name="password"
              errors={errors}
              register={register}
            />

            <FormField
              required
              label={t('ui.form.confirm_password')}
              type="password"
              name="password2"
              errors={errors}
              register={register}
            />
          </GroupFormField>

          <FormField
            select
            label="Account Type"
            name="account_type"
            errors={errors}
            register={register}
            defaultValue="normal"
          >
            <MenuItem value="normal" selected>
              Normal
            </MenuItem>
            <MenuItem value="doctor">Doctor</MenuItem>
          </FormField>

          {isDoctor && (
            <Controller
              name="certificate"
              control={control}
              rules={{ required: isDoctor }}
              render={({ field, fieldState }) => (
                <MuiFileInput
                  {...field}
                  size="small"
                  label={t('ui.form.certificate')}
                  inputProps={{ accept: 'application/pdf' }}
                  placeholder={t('ui.form.choose_file')}
                  error={fieldState.invalid}
                  helperText={fieldState.invalid ? 'File is invalid' : ''}
                  value={field.value as unknown as File | null}
                  clearIconButtonProps={{ children: <Close fontSize="small" /> }}
                  InputProps={{ startAdornment: <AttachFile /> }}
                  required={isDoctor}
                  hidden={!isDoctor}
                  sx={{
                    display: isDoctor ? 'inherit' : 'none',
                    mt: 2,
                  }}
                />
              )}
            />
          )}

          <FormControl sx={{ mt: 2 }}>
            <Button type="submit" variant="contained" disabled={isLoading}>
              {isLoading && <CircularProgress size={24} />}
              {t('ui.button.register')}
            </Button>
          </FormControl>

          <Divider sx={{ mt: 2 }}>
            <Typography variant="caption">or</Typography>
          </Divider>

          <FormControl sx={{ mt: 2 }}>
            <GoogleSignUp />
          </FormControl>
        </Form>
      </Col>
    </Row>
  )
}

export default RegisterForm
