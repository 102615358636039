import { Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import FullScreenOverlay from '~/components/UI/FullScreenOverlay'
import { useNotification } from '~/components/UI/NotificationProvider'
import { Booking } from '~/helpers/types/booking'
import { Post } from '~/helpers/types/post'
import useCalendarSlotSelection from '~/hooks/useCalendarSlotSelection'
import { useCreateBookingMutation } from '~/redux/features/api/booking-api-slice'
import BookingCalendar from './BookingCalendar'
import ProviderSelect from './ProviderSelect'

interface BookingModalProps {
  postDetail: Post
  closeModal: () => void
  onSuccess: (bookingId: number) => void
}

const BookingModal = ({ postDetail, closeModal, onSuccess }: BookingModalProps) => {
  const { showNotification } = useNotification()
  const [selectedProvider, setSelectedProvider] = useState<number>(-1)
  const [createBooking, { isLoading: isBookingLoading }] = useCreateBookingMutation()
  const {
    slots,
    isLoading,
    currentDate,
    setCurrentDate,
    selectedSlot,
    setSelectedSlot,
    calendarStartingDate,
  } = useCalendarSlotSelection({ postDetail, selectedProvider })

  const handleProviderChange = (providerId: number) => {
    setSelectedProvider(providerId)
  }

  const handleCalendarChange = (newDate: Dayjs) => {
    if (!currentDate.isSame(newDate)) {
      setCurrentDate(newDate)
      setSelectedSlot(null)
    }
  }

  const onCreateBookingSuccess = (booking: Booking) => {
    // const { id: bookingId } = booking;
    showNotification('Booking successful!', 'success')
    closeModal()
    onSuccess(booking.id)
  }

  const onError = (error: any) => {
    showNotification('Oops! There was an error while booking the service.', 'error')
  }

  const handleCreateBooking = () => {
    if (selectedSlot && selectedProvider) {
      createBooking({
        post: postDetail.id,
        provider: selectedProvider,
        startDatetime: selectedSlot,
        endDateTime: selectedSlot.add(postDetail.duration, 'minutes'),
      })
        .unwrap()
        .then(onCreateBookingSuccess)
        .catch(onError)
    }
  }

  const handleSlotChange = (newSlot: Dayjs | null) => {
    setSelectedSlot(newSlot)
  }

  // reset slot to null when provider is changed
  useEffect(() => {
    if (selectedProvider !== -1) {
      setSelectedSlot(null)
    }
  }, [selectedProvider, setSelectedSlot])

  const calendarDisabled = isLoading
  const canSubmit = selectedProvider !== -1 && selectedSlot !== null && !calendarDisabled

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} open={true} onClose={closeModal}>
      {isBookingLoading && <FullScreenOverlay />}

      <DialogTitle>Booking</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ProviderSelect postId={postDetail.id} onProviderChange={handleProviderChange} />
          </Grid>

          <Grid item xs={6}>
            <BookingCalendar
              calendarDisabled={calendarDisabled}
              calendarStartingDate={calendarStartingDate}
              onCalendarChange={handleCalendarChange}
              onSlotChange={handleSlotChange}
              selectedSlot={selectedSlot}
              slots={slots}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreateBooking} disabled={!canSubmit}>
          Create Booking
        </Button>
        <Button onClick={closeModal}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default BookingModal
