import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import header from '~/_mock/header'
import LocalizationDropdown from '~/components/LocalizationDropdown'
import MyProfile from '~/components/MyProfile'
import Section from '~/components/UI/Section'
import { useNavigationDrawer } from '~/providers/NavigationDrawer'
import { useAppSelector } from '~/redux/hooks'
import { AppBar, ButtonLogo, MainNav } from './Header.styled'
import { HeaderProps } from './Header.types'

const Header: FC<HeaderProps> = () => {
  const { t } = useTranslation()
  const { authenticated } = useAppSelector((state) => state.auth)
  const { open, setOpen } = useNavigationDrawer()

  const handleNavigationDrawerToggle = () => {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Section
          size="normal"
          padding={false}
          outerBoxProps={{ sx: { backgroundColor: 'transparent' } }}
          innerBoxProps={{ sx: { backgroundColor: 'transparent' } }}
        >
          <Toolbar
            disableGutters
            sx={{ flexGrow: 1, justifyContent: { xs: 'space-between', lg: undefined } }}
          >
            <ButtonLogo to="/">
              <Typography variant="h6">Given</Typography>
            </ButtonLogo>

            {header.menu && (
              <MainNav>
                {header.menu?.map(({ to, label }) => (
                  <Button
                    key={to}
                    disableElevation
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    component={Link}
                    variant="text"
                    color="inherit"
                    to={to}
                  >
                    {t(label)}
                  </Button>
                ))}
              </MainNav>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {authenticated && <MyProfile sx={{ display: { xs: 'none', lg: 'block' } }} />}

              {!authenticated && <Auth sx={{ display: { xs: 'none', lg: 'block' } }} />}

              <LocalizationDropdown />

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleNavigationDrawerToggle}
                sx={{ display: { lg: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Section>
      </AppBar>

      <Toolbar />
    </>
  )
}

export default Header

const Auth: FC<{ sx?: SxProps<Theme> }> = ({ sx }) => {
  const auth = header.auth
  const { t } = useTranslation()

  return (
    <>
      {auth?.map(({ label, to, ...props }) => (
        <Box key={to} sx={sx}>
          <Button component={Link} color="inherit" to={to} {...props}>
            {t(label)}
          </Button>
        </Box>
      ))}
    </>
  )
}
