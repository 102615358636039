import { selectAuth } from '~/redux/features/auth/auth-slice'
import { useAppSelector } from '~/redux/hooks'

// useContactForm.ts
export const useContactForm = <T>() => {
  const { account_detail } = useAppSelector(selectAuth)

  const defaultValues = {
    name:
      `${account_detail?.first_name || ''} ${account_detail?.last_name || ''}`.trim() || undefined,
    email: account_detail?.email || undefined,
  }

  const onSubmit = (data: T) => {
    console.log(data)
    // Handle form submission here
  }

  return { onSubmit, defaultValues }
}
