export const urls = {
  argsToParams: (args: Record<string, any>): string => {
    const params = Object.entries(args)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    return params ? `?${params}` : "";
  },
};
