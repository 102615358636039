import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar, View, momentLocalizer } from "react-big-calendar";
import {
  GivenEvent,
  useUpdateBookingStatusMutation,
} from "../../../redux/features/api/booking-api-slice";
import CalendarDialog from "./CalendarDialog";
import CalendarDialogConfirmation from "./CalendarDialogConfirmation";

const localizer = momentLocalizer(moment);

export interface colors {
  backgroundColor: string;
  color: string;
}

const colorPicker = (status: GivenEvent["status"]): colors => {
  const backgroundPicker = {
    in_progress: "#FDBA74", // Softer orange
    completed: "#74c69d", // Calm green
    canceled: "#f28482", // Muted red
    accepted: "#a0c4ff", // Soft blue
  };

  const colorPicker = {
    in_progress: "#795548",
    completed: "#1B5E20",
    canceled: "#8B0000",
    accepted: "#1565C0",
  };

  return {
    backgroundColor: backgroundPicker[status],
    color: colorPicker[status],
  };
};

const eventStyleGetter = (event: GivenEvent) => {
  const { backgroundColor, color } = colorPicker(event.status);

  const newStyle = {
    backgroundColor: backgroundColor,
    color: color,
    borderRadius: "0px",
    border: "none",
  };

  return {
    className: event.status,
    style: newStyle,
  };
};

const GivenCalendar = ({ events = [] }: { events?: GivenEvent[] }) => {
  const theme = useTheme();
  const [view, setView] = useState<View>("month");
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmaton] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<GivenEvent | null>(null);
  const [fullScreenDialog, setFullScreenDialog] = useState(false);
  const [updateStatus, { isLoading, isError, isSuccess }] =
    useUpdateBookingStatusMutation();

  const handleEventSelect = (event: GivenEvent) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenConfirmaton(false);
  };

  const handleAccept = (bookingId: number, status: GivenEvent["status"]) => {
    updateStatus({ bookingId, status })
      .unwrap()
      .then(() => {
        setOpen(false);
        setSelectedEvent(null);
      })
      .catch((error) => {
        console.error("Failed to update status", error);
      });
  };

  const handleReject = () => {
    setOpen(false);
    setOpenConfirmaton(true);
  };

  const handleRejectConfirm = () => {
    setOpen(true);
    setOpenConfirmaton(false);
  };

  const handleConfirm = (bookingId: number, status: GivenEvent["status"]) => {
    updateStatus({ bookingId, status })
      .unwrap()
      .then(() => {
        setOpenConfirmaton(false);
        setSelectedEvent(null);
      })
      .catch((error) => {
        console.error("Failed to update status", error);
      });
  };

  const handleViewChange = (newView: View) => {
    setView(newView);
  };

  const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (matchesXS) {
      setView("week");
      setFullScreenDialog(true);
    } else {
      setView("month");
      setFullScreenDialog(false);
    }
  }, [matchesXS]);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        borderRadius: 2,
        backgroundColor: "background.paper",
        justifyContent: "space-between",
        display: "flex",
        ":hover": {
          boxShadow:
            "0px 3px 3px -2px rgba(0,0,0,0.8),0px 3px 4px 0px rgba(0,0,0,0.20),0px 1px 8px 0px rgba(0,0,0,0.18)",
        },
      }}
    >
      <Stack direction="column" spacing={2} width={"100%"} p={0}>
        <Typography
          variant="h6"
          sx={{ color: "text.primary", marginBottom: 3 }}
        >
          Calendar
        </Typography>
        <Box
          sx={{
            minHeight: { xs: "900px" },
            maxHeight: { xs: "900px" },
            width: "100%",
          }}
        >
          <Calendar
            localizer={localizer}
            view={view}
            onView={handleViewChange}
            onSelectEvent={handleEventSelect}
            events={events}
            eventPropGetter={eventStyleGetter}
          />
          {selectedEvent && selectedEvent.start && (
            <>
              <CalendarDialog
                open={open}
                selectedEvent={selectedEvent}
                handleClose={handleClose}
                handleAccept={() =>
                  handleAccept(
                    selectedEvent.id,
                    selectedEvent.status === "in_progress"
                      ? "accepted"
                      : "completed"
                  )
                }
                handleReject={handleReject}
                fullScreenDialog={fullScreenDialog}
                colors={colorPicker(selectedEvent.status)}
              />
              <CalendarDialogConfirmation
                open={openConfirmation}
                status={selectedEvent.status}
                handleClose={handleRejectConfirm}
                handleReject={handleRejectConfirm}
                handleAccept={() =>
                  handleConfirm(
                    selectedEvent.id,
                    selectedEvent.status === "in_progress"
                      ? "canceled"
                      : "canceled"
                  )
                }
                fullScreenDialog={fullScreenDialog}
              />
            </>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};

export default GivenCalendar;
