import { Avatar } from '@mui/material'
import { selectAuth } from '~/redux/features/auth/auth-slice'
import { useAppSelector } from '~/redux/hooks'

function UserAvatar() {
  const { account_detail } = useAppSelector(selectAuth)
  const { avatar = '', first_name = '', last_name = '' } = account_detail || {}

  const photo = avatar.startsWith('http') ? avatar : 'http://localhost:8000'.concat(avatar)
  const name = first_name.concat(` ${last_name}`)

  return <Avatar alt={name} src={photo} sx={{ width: 32, height: 32 }} />
}

export default UserAvatar
