import { Button, FormControl } from '@mui/material'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Form, { FormField } from '~/components/Form'
import GroupFormField from '~/components/Form/GroupFormField'
import { useNotification } from '~/components/UI/NotificationProvider'
import { useActivationResendMutation } from '~/redux/features/api/account-api-slice'
import {
  useZodRequestPasswordReset,
  UseZodRequestPasswordResetSchema,
} from '../FormPasswordRequestReset/useZodPasswordRequestReset'

const FormActivationRequestLink = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showNotification } = useNotification()

  // Define your form schema using zod and translations
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useZodRequestPasswordReset({ email: '' })
  const [reqActivationLink, { isLoading, isError, isSuccess }] = useActivationResendMutation()

  function onError(msg: string) {
    showNotification(msg, 'error')
  }

  const onSubmit: SubmitHandler<UseZodRequestPasswordResetSchema> = async (data) => {
    try {
      const response = await reqActivationLink(data).unwrap()
      console.log('\n=== response ===')
      console.log(response)
      console.log('===\n')

      if (isError) {
        return onError(t('ui.password_reset_error_message'))
      }

      if (isSuccess && !isLoading) {
        showNotification(t('ui.password_reset_success_message'), 'success')

        navigate('/login')

        return
      }
    } catch (err: unknown) {
      onError(t('ui.api.something_went_wrong'))
    }
  }

  return (
    <Form noValidate size="small" onSubmit={handleSubmit(onSubmit)} register={register}>
      <GroupFormField>
        <FormField
          required
          label={t('ui.form.email')}
          type="email"
          name="email"
          errors={errors}
          register={register}
        />
      </GroupFormField>

      <FormControl sx={{ mt: 2 }}>
        <Button type="submit" variant="contained">
          {t('ui.button.submit')}
        </Button>
      </FormControl>
    </Form>
  )
}

export default FormActivationRequestLink
