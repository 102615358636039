import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

const CalendarDialogConfirmation = ({
  open,
  handleClose,
  handleAccept,
  handleReject,
  status,
  fullScreenDialog,
}: {
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  handleReject: () => void;
  status: string;
  fullScreenDialog: boolean;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreenDialog}
      PaperProps={{
        style: {
          borderRadius: "16px",
          backgroundColor: "background.paper",
          justifyContent: "space-between",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          mb: 2,
        }}
      >
        Confirmation
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "primary.contrastText",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to{" "}
        {status === "in_progress" ? "reject" : "cancel"} this event?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject} variant="outlined">
          No
        </Button>
        <Button onClick={handleAccept} variant="contained">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalendarDialogConfirmation;
