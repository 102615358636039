const about = {
  title: 'À propos de nous',
  description:
    'Nous sommes un groupe de personnes passionnées qui cherchent à faire une différence dans le monde. Nous sommes déterminés à avoir un impact positif et à apporter des changements significatifs.',
  team: {
    title: 'Notre Équipe',
    description:
      'Nous sommes un groupe de personnes passionnées qui cherchent à faire une différence dans le monde. Nous sommes déterminés à avoir un impact positif et à apporter des changements significatifs.',
  },
}

export default about
