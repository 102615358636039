import { ThemeProvider as ThemeWrapper } from '@mui/material/styles'
import { ThemeProviderProps } from '@mui/material/styles/ThemeProvider'
import { FC } from 'react'
import { createThemeWithDefaults } from '~/themes/themeDefaults'

export type PropsWithThemeProvider<P = unknown> = P & Omit<ThemeProviderProps, 'theme'>

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightSemiBold: React.CSSProperties['fontWeight']
  }
}
declare module '@mui/system' {
  interface Shape {
    borderRadiusSmall: number
    borderRadiusLarge: number
  }
}

const ThemeProvider: FC<PropsWithThemeProvider> = ({ children }) => {
  const themeCtx = createThemeWithDefaults()

  return <ThemeWrapper theme={themeCtx}>{children}</ThemeWrapper>
}

export default ThemeProvider
