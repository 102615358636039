import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useZodHoneypotSchema } from '~/components/Form/useZodHoneypot'

// Define the schema type explicitly
export type UseZodLoginSchema = z.infer<ReturnType<typeof useZodLoginSchema>>

// Hook for the login form
export const useZodLogin = (defaultValues: Partial<UseZodLoginSchema> = {}) => {
  const schema = useZodLoginSchema()

  const formMethods = useForm<UseZodLoginSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  })

  return formMethods
}

export const useZodLoginSchema = () => {
  const honeypot = useZodHoneypotSchema()
  const { t } = useTranslation()

  return honeypot.extend({
    email: z.string().email({ message: t('ui.form.email_invalid') }),
    password: z.string().min(8, { message: t('ui.form.password_min_length') }),
  })
}
