import ProfileForm from '~/components/FormProfile'
import Section from '~/components/UI/Section'

const ProfilePage = () => (
  <Section>
    <ProfileForm />
  </Section>
)

export default ProfilePage
