import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useZodHoneypotSchema } from '~/components/Form/useZodHoneypot'

// RequestPasswordReset schema and hook
export type UseZodRequestPasswordResetSchema = z.infer<
  ReturnType<typeof useZodRequestPasswordResetSchema>
>

export const useZodRequestPasswordReset = (
  defaultValues: Partial<UseZodRequestPasswordResetSchema> = {},
) => {
  const schema = useZodRequestPasswordResetSchema()

  return useForm<UseZodRequestPasswordResetSchema>({
    resolver: zodResolver(schema),
    defaultValues,
  })
}

export const useZodRequestPasswordResetSchema = () => {
  const honeypot = useZodHoneypotSchema()
  const { t } = useTranslation()

  return honeypot.extend({
    email: z.string().email({ message: t('ui.form.email_invalid') }),
  })
}
