import { Favorite, FavoriteBorder } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import BookingCreatedModal from '~/components/Booking/BookingCreatedModal'
import BookingModal from '~/components/Booking/BookingModal'
import LoadingSpinner from '~/components/UI/LoadingSpinner'
import { Post } from '~/helpers/types/post'
import { map } from '~/helpers/utils/mappings'
import { useToggleFavoritePostMutation } from '~/redux/features/api/management-api-slice'
import { navigationActions } from '~/redux/features/navigation/navigation-slice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'

const ProductDetail = ({ postDetail }: { postDetail: Post }) => {
  const [bookingId, setBookingId] = useState(-1)
  const [bookingModalOpen, setBookingModalOpen] = useState(false)
  const [completeModalOpen, setCompleteModalOpen] = useState(false)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const authenticated = useAppSelector((state) => state.auth.authenticated)
  const [toggleFavoritePost, { isLoading: isFavoriteLoading }] = useToggleFavoritePostMutation()

  const handleOpenBookingModal = () => {
    if (authenticated) {
      setBookingModalOpen(true)
    } else {
      dispatch(navigationActions.setIntendedRoute(location.pathname))
      navigate(`/login`)
    }
  }

  const handleCloseBookingModal = () => {
    setBookingModalOpen(false)
  }

  const handleCloseCompleteBookingModal = () => {
    setCompleteModalOpen(false)
  }

  const handleFavoriteToggle = async (postId: number) => {
    const formData = new FormData()
    formData.append('post_id', postId.toString())

    await toggleFavoritePost({
      formData,
      post_id: postId,
    }).unwrap()
  }

  const handleAddToFavorites = () => {
    if (authenticated) {
      handleFavoriteToggle(postDetail.id)
    } else {
      dispatch(navigationActions.setIntendedRoute(location.pathname))
      navigate(`/login`)
    }
  }

  const handleBookingCreated = (bookingId: number) => {
    setBookingId(bookingId)
    setBookingModalOpen(false)
    setCompleteModalOpen(true)
  }

  let openModalComponent = null

  if (bookingModalOpen) {
    openModalComponent = (
      <BookingModal
        postDetail={postDetail}
        closeModal={handleCloseBookingModal}
        onSuccess={handleBookingCreated}
      />
    )
  } else if (completeModalOpen) {
    openModalComponent = (
      <BookingCreatedModal
        bookingId={bookingId}
        postId={postDetail.id}
        closeModal={handleCloseCompleteBookingModal}
        open={completeModalOpen}
      />
    )
  }

  const handleNavigateToMessages = () => {
    navigate(`/management/chat/?contact=${postDetail.admin}`)
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '50px',
          paddingBottom: '50px',
        }}
      >
        <Grid item xs={8}>
          <Typography variant="h4" gutterBottom>
            {postDetail.title}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            aria-label="add to favorites"
            sx={{ float: 'right' }}
            onClick={handleAddToFavorites}
          >
            {postDetail.is_favorite
              ? !isFavoriteLoading && <Favorite />
              : !isFavoriteLoading && <FavoriteBorder />}
            {isFavoriteLoading && <LoadingSpinner />}
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {map.currencyCodeToSymbol(postDetail.currency_code)} {postDetail.price}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary">
            {postDetail.description}
          </Typography>
        </Grid>
      </Grid>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '50%' }}
          onClick={handleNavigateToMessages}
        >
          Contact Service Provider
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: '50%' }}
          onClick={handleOpenBookingModal}
        >
          {t('book_now')}
        </Button>
      </Stack>
      {openModalComponent}
    </React.Fragment>
  )
}

export default ProductDetail
