import { CssBaseline } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { NotificationProvider } from '~/components/UI/NotificationProvider'
import { store } from '~/redux/store'
import GlobalStyles from '~/themes/GlobalStyles'
import NavigationDrawerProvider from './NavigationDrawer'
import ThemeProvider from './ThemeProvider/themeProvider'

type ProvidersProps = PropsWithChildren

const Providers: FC<PropsWithChildren<ProvidersProps>> = ({ children }) => {
  return (
    <Provider store={store}>
      <NavigationDrawerProvider>
        <NotificationProvider>
          <ThemeProvider>
            <CssBaseline />
            <GlobalStyles />

            {children}
          </ThemeProvider>
        </NotificationProvider>
      </NavigationDrawerProvider>
    </Provider>
  )
}

export default Providers
