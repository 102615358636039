import { Dayjs } from "dayjs";

const doIntervalsOverlap = (startA: Dayjs, endA: Dayjs, startB: Dayjs, endB: Dayjs): boolean => {
  // Check if either interval is empty
  if (startA.isAfter(endA) || startB.isAfter(endB)) {
    return false;
  }

  // Check if the intervals overlap
  return startA.isBefore(endB) && startB.isBefore(endA);
};

export const dates = {
  calculateTimeSlots: (
    startDate: Dayjs,
    endDate: Dayjs,
    durationInMinutes: number,
    occupiedSlots: { start: Dayjs; end: Dayjs; client: number | null }[] = []
  ) => {
    const timeSlots: any = [];

    // Validate input dates
    if (!startDate.isValid() || !endDate.isValid() || endDate.isBefore(startDate)) {
      return timeSlots; // Return empty array if dates are invalid or in the wrong order
    }

    let startCurrentSlot = startDate;
    let endCurrentSlot = startDate.add(durationInMinutes, "minute");
    // Loop until the current slot is less or equal than the end datetime
    while (endCurrentSlot.isBefore(endDate) || endCurrentSlot.isSame(endDate)) {
      let shouldAddSlot = true;
      let client = null;
      // Check if the current slot falls within any of the slots to remove
      for (const slotToRemove of occupiedSlots) {
        if (doIntervalsOverlap(startCurrentSlot, endCurrentSlot, slotToRemove.start, slotToRemove.end)) {
          shouldAddSlot = false;
          client = slotToRemove.client;
          break; // Exit the loop early if we find a slot to remove
        }
      }

      timeSlots.push({ startTime: startCurrentSlot, occupied: !shouldAddSlot, client: client });

      endCurrentSlot = endCurrentSlot.add(durationInMinutes, "minute");
      startCurrentSlot = startCurrentSlot.add(durationInMinutes, "minute");
    }

    return timeSlots;
  },
};
