import Box, { BoxProps } from '@mui/material/Box'
import { ComponentProps, FC, PropsWithChildren, useEffect, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { Form as Component } from './Form.styled'

export type FormProps = {
  register: UseFormRegister<any>
  reCaptchaId?: string
  size?: 'small' | 'normal'
} & ComponentProps<'form'> &
  Omit<BoxProps, 'component'>

/**
 * Form component that wraps its children in a styled form element.
 *
 * @param {Object} props - The properties object.
 * @param {'register' | 'normal'} [props.type='normal'] - The type of the form, which can be either 'register' or 'normal'.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the form.
 * @param {Object} props.rest - Additional properties passed to the form element.
 *
 * @returns {JSX.Element} The rendered form component.
 */
const Form: FC<PropsWithChildren<FormProps>> = ({
  children,
  reCaptchaId,
  register,
  size = 'normal',
  ...props
}) => {
  const [reCaptchaToken, setReCaptchaToken] = useState('')

  useEffect(() => {
    if (!reCaptchaId) return void 0

    // Load reCAPTCHA script and generate token
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaId}`
    script.async = true
    document.body.appendChild(script)

    script.onload = () => {
      ;(window as any).grecaptcha.ready(() => {
        ;(window as any).grecaptcha
          .execute('YOUR_SITE_KEY', { action: 'submit' })
          .then((token: string) => {
            setReCaptchaToken(token)
          })
      })
    }
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Component component="form" size={size} {...props}>
      {children}

      <div />

      {/* Hidden honeypot field */}
      <Box
        component="input"
        type="text"
        {...register('hiddenField')}
        sx={{ outline: '1px solid red' }}
      />

      {reCaptchaId && (
        <>
          {/* Hidden reCAPTCHA token field */}
          <input type="hidden" {...register('g-recaptcha-response')} value={reCaptchaToken} />
        </>
      )}
    </Component>
  )
}

export default Form
