const data = [
  {
    id: 'ivb204b',
    title: 'Discover & Choose Your Service',
    description: 'Explore a diverse selection of professional services.',
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
  {
    id: 'dvjn0b2b',
    title: 'Book An Appointment',
    description: 'Find available service providers near you and book an appointment.',
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
  {
    id: 'sodkvjnb',
    title: 'Service Completion',
    description: 'Enjoy the convenience of having a professional come to you.',
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
] as const

export default data
