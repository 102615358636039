import { Box, Skeleton } from "@mui/material";

const PostDetailBoxSkeleton = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }} padding={2}>
      <Skeleton variant="rectangular" sx={{ height: "100%", width: "80%" }} />

      <Box sx={{ width: "100%", padding: "1rem" }}>
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      </Box>
    </Box>
  );
};

export default PostDetailBoxSkeleton;
