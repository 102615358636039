import Box, { BoxProps } from '@mui/material/Box'
import { Children, FC, PropsWithChildren } from 'react'

const GroupFormField: FC<PropsWithChildren<BoxProps>> = ({ children, sx, ...props }) => {
  const length = Children.count(children)

  // Generate grid template columns based on the number of children
  const gridTemplateColumns = length > 1 ? `repeat(${length}, 1fr)` : '1fr'

  return (
    <Box
      {...props}
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: `1fr`, md: gridTemplateColumns },
        columnGap: 2,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default GroupFormField
