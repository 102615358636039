import styled from '@emotion/styled'
import { FC } from 'react'
import { ImageProps } from './Image.types'

const Image: FC<ImageProps> = ({ ...props }) => <StyledImage {...props} />

export default Image

export const StyledImage = styled('img')(({ theme }) => ({
  verticalAlign: 'middle',
  display: 'inline-block',
  maxWidth: '100%',
  width: 'stretch',
  height: 'auto',
}))
