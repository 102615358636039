import { Box, Divider, Typography } from '@mui/material'

const SectionTitle = (props: { title: string }) => {
  return (
    <Box width="100%">
      <Typography padding={2} component="h2" variant="h4">
        {props.title}
      </Typography>
      <Divider />
    </Box>
  )
}

export default SectionTitle
