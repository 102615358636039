import {
  Autocomplete,
  CircularProgress,
  InputLabelProps,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material'
import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Practitioner from '~/components/Practitioner'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'
import useSearchMapLocations, { MapLocation } from '~/hooks/useSearchMapLocations'
import {
  PostPractitioners,
  useListPostsQuery,
  usePostPractitionersQuery,
} from '~/redux/features/api/posts-api-slice'
import {
  Category,
  SubCategory,
  useGetCategoriesWithSubCategoriesQuery,
} from '~/redux/features/categories/categories-slice'

interface Coordinates {
  latitude: number
  longitude: number
}

const Page: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const practitionerParam = searchParams.get('practitioner')
  const categoryParam = searchParams.get('category')
  const subcategoryParam = searchParams.get('subcategory')
  const latitudeParam = searchParams.get('latitude')
  const longitudeParam = searchParams.get('longitude')

  const [categories, setCategories] = useState<Category[]>([])
  const [allSubcategories, setAllSubcategories] = useState<SubCategory[]>([])
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState<SubCategory | null>(null)

  const {
    isLoading: categoriesAreLoading,
    data: categoriesData,
    isSuccess: categoriesIsSuccess,
  } = useGetCategoriesWithSubCategoriesQuery({
    practitioner: practitionerParam ? Number(practitionerParam) : undefined,
  })

  const handleCategoryChange = (event: any, value: Category | null) => {
    setSelectedCategory(value)
    setSelectedSubcategory(null)
    if (value) {
      setSearchParams({ category: String(value.id), ...Object.fromEntries(searchParams.entries()) })
    } else {
      searchParams.delete('category')
      searchParams.delete('subcategory')
      setSearchParams(searchParams)
    }
  }

  const handleSubcategoryChange = (event: any, value: SubCategory | null) => {
    if (value) {
      const relatedCategory: Category[] = categories.filter(
        (category) => category.id === value?.category.id,
      )
      setSearchParams({
        category: String(relatedCategory[0].id),
        subcategory: String(value.id),
        ...Object.fromEntries(searchParams.entries()),
      })
      setSelectedSubcategory(value)
      setSelectedCategory(relatedCategory[0])
    } else {
      searchParams.delete('subcategory')
      setSearchParams(searchParams)
      setSelectedSubcategory(value)
    }
  }

  useEffect(() => {
    if (categoriesData) {
      const allSubCats = categoriesData.flatMap((category: Category) => category.subcategories)
      setCategories(categoriesData)
      setAllSubcategories(allSubCats)
      if (categoryParam && !subcategoryParam) {
        setSelectedCategory(
          categoriesData.filter((category) => category.id === Number(categoryParam))[0],
        )
      }
      if (subcategoryParam) {
        const subCatParam = allSubCats.filter((subcat) => subcat.id === Number(subcategoryParam))[0]
        setSelectedCategory(
          categoriesData.filter((category) => category.id === subCatParam.category.id)[0],
        )
        setSelectedSubcategory(subCatParam)
      }
    }
  }, [categoriesIsSuccess, categoriesData])

  // start: fetch posts data
  const { isLoading, data, isSuccess, isError } = useListPostsQuery({
    page: page + 1,
    limit: rowsPerPage,
    practitioner: practitionerParam ? Number(practitionerParam) : undefined,
    category: categoryParam ? Number(categoryParam) : undefined,
    subcategory: subcategoryParam ? Number(subcategoryParam) : undefined,
    latitude: latitudeParam ? Number(latitudeParam) : undefined,
    longitude: longitudeParam ? Number(longitudeParam) : undefined,
  })
  const { results = [], count = 0 } = useMemo(() => ({ ...data }), [data])
  // end: fetch posts data

  // start: handle Pagination
  const handleChangePage = (_: any, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  // end: handle Pagination

  // start: location dropdown
  const [address, setAdress] = useState<string>()
  const [getFromCoordinatesParams, setGetFromCoordinatesParams] = useState<boolean>(
    Boolean(latitudeParam && longitudeParam),
  )

  const onAddressChange = (address: string) => {
    setAdress(address)
  }

  const {
    locations,
    setLocations,
    setSearch,
    mapLocation,
    getAddressFromCoordinates,
    setMapLocation,
    loading,
  } = useSearchMapLocations({
    onAddressChange,
  })

  if (getFromCoordinatesParams) {
    setGetFromCoordinatesParams(false)
    getAddressFromCoordinates({
      latitude: Number(latitudeParam),
      longitude: Number(longitudeParam),
    })
  }

  const handleSearchInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: string,
  ) => {
    if (value === null || reason !== 'input') return
    setSearch(value)
  }

  const handleOptionChange = (event: SyntheticEvent, value: MapLocation | null) => {
    if (value === null) {
      searchParams.delete('latitude')
      searchParams.delete('longitude')
      setSearchParams({ ...Object.fromEntries(searchParams.entries()) })
      return
    }

    const { latitude, longitude, address } = value
    setMapLocation((prev) => ({ ...prev, latitude, longitude, address }))

    onAddressChange(address)
    setLocations(locations.filter((location) => location.address === address))
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      latitude: String(latitude),
      longitude: String(longitude),
    })
  }

  // end: location dropdown

  // start: practitioner dropdown
  const [selectedPractitioner, setSelectedPractitioner] = useState<PostPractitioners | null>(null)

  const {
    isLoading: practitionersAreLoading,
    data: practitionersData,
    isSuccess: practitionersIsSuccess,
  } = usePostPractitionersQuery({
    category: categoryParam ? Number(categoryParam) : undefined,
    subcategory: subcategoryParam ? Number(subcategoryParam) : undefined,
  })

  useEffect(() => {
    if (practitionersIsSuccess) {
      setSelectedPractitioner(
        practitionersData.filter(
          (practitioner) => practitioner.id === Number(practitionerParam),
        )[0],
      )
    }
  }, [practitionersIsSuccess, practitionersData])

  const handlePractitionerChange = (event: SyntheticEvent, value: PostPractitioners | null) => {
    if (value === null) {
      searchParams.delete('practitioner')
      setSelectedPractitioner(null)
      setSearchParams({ ...Object.fromEntries(searchParams.entries()) })
      return
    }

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      practitioner: String(value.id),
    })
    setSelectedPractitioner(value)
  }
  // start: practitioner dropdown

  return (
    <Section size="small" padding={[{ target: 'y', value: 'normal' }]}>
      <Row gap={(theme) => theme.spacing(3)}>
        <Col xs={12} gap={(theme) => theme.spacing(2)} display="flex" justifyContent="space-evenly">
          <Autocomplete
            options={categories}
            getOptionLabel={(option) => option.title}
            value={selectedCategory}
            onChange={handleCategoryChange}
            renderInput={(params) => (
              <TextField {...params} label="Select a category" variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            style={{ flexGrow: 1, minWidth: '200px', maxWidth: '400px' }}
            disabled={!categoriesIsSuccess || categoriesAreLoading}
          />
          <Autocomplete
            options={selectedCategory ? selectedCategory.subcategories : allSubcategories}
            getOptionLabel={(option) => option.title}
            value={selectedSubcategory}
            onChange={handleSubcategoryChange}
            renderInput={(params) => (
              <TextField {...params} label="Select a subcategory" variant="outlined" />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            style={{ flexGrow: 1, minWidth: '200px', maxWidth: '400px' }}
            disabled={!categoriesIsSuccess || categoriesAreLoading}
          />
          <Autocomplete
            options={practitionersData ?? []}
            loading={practitionersAreLoading}
            value={selectedPractitioner}
            getOptionLabel={(option) => option.name}
            onChange={handlePractitionerChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Practitioner"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            style={{ flexGrow: 1, minWidth: '200px', maxWidth: '400px' }}
            disabled={!categoriesIsSuccess || categoriesAreLoading}
          />
          <Autocomplete
            fullWidth
            disableClearable
            options={locations}
            getOptionLabel={(option) => option.address}
            onInputChange={handleSearchInputChange}
            onChange={handleOptionChange}
            loading={loading}
            value={mapLocation}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Close to"
                variant="outlined"
                placeholder="Search for a location"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading && <CircularProgress color="inherit" size={20} />}
                    </React.Fragment>
                  ),
                }}
                InputLabelProps={{
                  ...(params.InputLabelProps as Partial<InputLabelProps>),
                }}
              />
            )}
          />
          {/* <Button
            variant="contained"
            type="submit"
            onClick={handleApplyFilters}
            disabled={!categoriesIsSuccess || categoriesAreLoading}
          >
            {t('ui.apply')}
          </Button> */}
        </Col>

        {!isLoading && isError && (
          <Col xs={12}>
            <Typography variant="h4">Something went wrong!!</Typography>
          </Col>
        )}

        {!isError && (
          <>
            <Col xs={12} display="grid" gap="16px">
              {isLoading && (
                <>
                  {Array.from({ length: rowsPerPage }).map((_, idx) => (
                    <Practitioner.Skeleton key={idx} />
                  ))}
                </>
              )}

              {!isLoading &&
                results?.map(({ id, ...practitioner }) => (
                  <Practitioner key={id} {...practitioner} />
                ))}
            </Col>

            {!isLoading && count && count / rowsPerPage > 1 && (
              <Col xs={12}>
                <TablePagination
                  component="div"
                  count={count || 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]} //[5, 10, 25, { label: 'All', value: -1 }]}
                  sx={{ marginLeft: 'auto' }}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </Section>
  )
}

export default Page
