import { apiSlice } from './api-slice'

interface BaseMatches {
  name: string
  id: number
}

export interface PractitionerMatches extends BaseMatches {
  type: 'Practitioner Name'
}

export interface CategoryMatches extends BaseMatches {
  type: 'Category'
}

export interface SubcategoryMatches extends BaseMatches {
  type: 'Subcategory'
  category_id: number
}

export interface Suggestions {
  user_matches: PractitionerMatches[]
  category_matches: CategoryMatches[]
  subcategory_matches: SubcategoryMatches[]
}

interface GetSuggestionsInput {
  query: string
}

export const suggestionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestions: builder.query<Suggestions, GetSuggestionsInput>({
      query: (args: { query: string }) => {
        const { query } = args
        return `/search?q=${query}`
      },
    }),
  }),
})

export const { useGetSuggestionsQuery } = suggestionApiSlice
