import { useTranslation } from 'react-i18next'
import { DEFAULT_LANG } from '~/locales'

const translationLink = (path = '') => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation()

  // Get the current language
  const currentLanguage = i18n.language

  if (['/'].includes(path)) path = ''

  const newPath = `${currentLanguage === DEFAULT_LANG ? '' : '/'.concat(currentLanguage)}/${path}`
  const result = newPath.replace(/\/\//g, '/')

  return result
}

export default translationLink
