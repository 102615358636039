import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getCachedCookies } from "./cookies-actions";

export interface CookiesState {
  cookies: {
    type: string;
    accepted: boolean;
  }[];
}

const initialState = getCachedCookies() as CookiesState;

const cookiesSlice = createSlice({
  name: "cookies",
  initialState,
  reducers: {
    setCookies: (state, action: PayloadAction<CookiesState>) => {
      state.cookies = action.payload.cookies;
    },
    reset: (state) => {
      state.cookies = [];
    },
  },
});

export default cookiesSlice;

export const cookiesActions = cookiesSlice.actions;
