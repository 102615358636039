import { SxProps, Theme, styled } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

const Carousel: FC<PropsWithChildren<{ sx?: SxProps<Theme> }>> = ({ children, ...props }) => (
  <Slide
    loop
    navigation
    slidesPerView={1}
    modules={[Pagination, Navigation]}
    pagination={{ clickable: true, dynamicBullets: true }}
    autoplay={{ pauseOnMouseEnter: true, delay: 2500, disableOnInteraction: false }}
    {...props}
  >
    {children}
  </Slide>
)

const Slide = styled(Swiper)({
  height: '60dvh',
})

const Item = styled(SwiperSlide)({
  width: '100%',
  position: 'relative',

  '& > img': {
    all: 'unset',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
})

export default Object.assign(Carousel, {
  Item,
})
