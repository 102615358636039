// i18n.ts
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translations, { DEFAULT_LANG } from './locales'
// import HttpApi from 'i18next-http-backend'

const resources = translations.reduce(
  (acc, { translation, code }) => Object.assign({}, acc, { [code]: { translation } }),
  {},
)

i18n
  // .use(HttpApi) // Load translations using http (default public/locales)
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LANG,
    fallbackLng: DEFAULT_LANG,
    // debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    // backend: {
    //   loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to the translation files
    // },
  })

export default i18n
