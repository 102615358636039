const success = {
  light: '#1fc78f',
  main: '#1cb381',
  dark: '#00a870',
}
const warning = {
  light: '#ffcc00',
  main: '#edbd00',
  dark: '#dbaf00',
}
const error = {
  light: '#ff4538',
  main: '#e63e32',
  dark: '#b80c00',
}

export const color = {
  white: '#fffffe',
  black: '#000000',
  success,
  warning,
  error,
}

export type Color = Omit<typeof color, 'black' | 'success' | 'warning' | 'error'>
