import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { Post } from '~/helpers/types/post'
import { dates } from '~/helpers/utils/dates'
import { useGetBlockedSlotsQuery } from '~/redux/features/api/booking-api-slice'

interface UseCalendarSlotSelectionProps {
  postDetail: Post
  selectedProvider: number
}

const useCalendarSlotSelection = ({
  postDetail,
  selectedProvider,
}: UseCalendarSlotSelectionProps) => {
  const { availableFromTime, availableToTime } = useMemo(
    () => ({
      availableFromTime: dayjs(postDetail.available_from_time, 'HH:mm:ss'),
      availableToTime: dayjs(postDetail.available_to_time, 'HH:mm:ss'),
    }),
    [postDetail],
  )

  const { calendarStartingDate } = useMemo(
    () => ({
      calendarStartingDate: dayjs().startOf('day'),
    }),
    [],
  )

  const [currentMonth, setCurrentMonth] = useState<number>(dayjs().month())
  const [currentDate, setCurrentDate] = useState<Dayjs>(calendarStartingDate)
  const [selectedSlot, setSelectedSlot] = useState<Dayjs | null>(null)
  const [slots, setSlots] = useState<
    { startTime: Dayjs; occupied: boolean; client: number | null }[]
  >([])

  const { startDate, endDate } = useMemo(
    () => ({
      startDate: currentDate.startOf('month').subtract(1, 'day').format('YYYY-MM-DD'),
      endDate: currentDate.endOf('month').add(1, 'day').format('YYYY-MM-DD'),
    }),
    [currentDate],
  )
  const getBlockedSlotsQuery = useGetBlockedSlotsQuery({
    postId: postDetail.id,
    startDate,
    endDate,
  })

  // change month everytime the current date changes
  useEffect(() => {
    if (currentDate.month() !== currentMonth) {
      setCurrentMonth(currentDate.month())
    }
  }, [currentMonth, currentDate])

  // Calculate slots
  useEffect(() => {
    const startDate = currentDate
      .set('hour', availableFromTime.hour())
      .set('minute', availableFromTime.minute())
      .set('second', availableFromTime.second())

    const endDate = currentDate
      .set('hour', availableToTime.hour())
      .set('minute', availableToTime.minute())
      .set('second', availableToTime.second()) // Add 330 minutes as an example TODO get this from the psot working interval

    const blockedSlots = getBlockedSlotsQuery.data
      ? getBlockedSlotsQuery.data
          .filter((slot: { provider: number }) => slot.provider === selectedProvider)
          .map((slot: { start_datetime: string; end_datetime: string; client: number | null }) => ({
            start: dayjs(slot.start_datetime),
            end: dayjs(slot.end_datetime),
            client: slot.client,
          }))
      : []

    const timeSlots = dates.calculateTimeSlots(
      startDate,
      endDate,
      postDetail.duration,
      blockedSlots,
    )
    setSlots(timeSlots)
  }, [
    currentDate,
    getBlockedSlotsQuery.data,
    selectedProvider,
    availableFromTime,
    availableToTime,
    postDetail.duration,
  ])

  return {
    currentMonth,
    setCurrentMonth,
    currentDate,
    setCurrentDate,
    selectedSlot,
    setSelectedSlot,
    slots,
    setSlots,
    isLoading: getBlockedSlotsQuery.isLoading,
    calendarStartingDate,
  }
}

export default useCalendarSlotSelection
