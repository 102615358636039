import { CircularProgress } from "@mui/material";

const LoadingSpinner = ({
  size = 30,
  color = "primary",
}: {
  size?: number;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit";
}) => {
  return <CircularProgress color={color} size={size} />;
};

export default LoadingSpinner;
