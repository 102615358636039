import { useTranslation } from 'react-i18next'
import images from '~/_mock/images'
import FormContactUs from '~/components/FormContactUs'
import Media from '~/components/Media'

const Page = () => {
  const { t } = useTranslation()
  const { urls, alt_description: alt } = { ...images?.[1] }

  return (
    <>
      <FormContactUs />

      <Media
        title={t('ui.findUs')}
        size="small"
        media={{
          type: 'image',
          item: [{ src: urls?.regular, alt }],
        }}
      />
    </>
  )
}

export default Page
