import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Drawer {
  show: boolean;
}

export interface NavigationState {
  drawer: Drawer;
  intendedRoute?: string | unknown;
}

const initialState = {
  drawer: {
    show: false,
  },
} as NavigationState;

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    toggleDrawer(state) {
      state.drawer.show = !state.drawer.show;
    },
    setIntendedRoute: (state, action: PayloadAction<string | null>) => {
      state.intendedRoute = action.payload;
    },
  },
});

export default navigationSlice;

export const navigationActions = navigationSlice.actions;
