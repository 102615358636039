import axios, { CancelToken } from "axios";
import { json } from "react-router-dom";
import { config as apiConfig } from "../config/api-config";

export type RequestMethod = "GET" | "POST" | "PATCH" | "PUT" | "DELETE";

const headers = {
  "Cache-Control": "no-store",
};

class ApiService {
  apiUrl = "";
  token = "";

  setToken(token: string) {
    this.token = token;
  }

  setApiUrl(url: string) {
    this.apiUrl = url;
  }

  req = async (
    method: RequestMethod,
    endpoint: string,
    data?: any,
    params?: any,
    cancelToken?: CancelToken,
    authenticated = false
  ) => {
    try {
      let requestHeaders: any = {
        ...headers,
        "Content-Type": "application/json",
      };

      if (authenticated) {
        requestHeaders = {
          ...headers,
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        };
      }

      const response = await axios({
        url: `${this.apiUrl}${endpoint}`,
        method,
        headers: requestHeaders,
        data,
        params,
        cancelToken,
      });

      if (response.status < 200 || response.status > 300) {
        if (response.status === 401 || response.status === 403) {
          throw json({ message: "Unauthorized request." }.message, {
            status: 403,
          });
        }

        throw json({ message: "Could not conclude request." }.message, {
          status: 500,
        });
      }

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

const apiService = new ApiService();
apiService.setApiUrl(apiConfig.config.url);

export default apiService;
