import { Button, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Col, Row } from '~/components/UI/Grid'
import LoadingSpinner from '~/components/UI/LoadingSpinner'
import { useNotification } from '~/components/UI/NotificationProvider'
import Section from '~/components/UI/Section'
import { useActivationQuery } from '~/redux/features/api/account-api-slice'

const ValidationPage = () => {
  const { uuid, token } = useParams()
  const { t, i18n } = useTranslation()
  const { showNotification } = useNotification()

  const { data, isError, isLoading, isFetching, isSuccess } = useActivationQuery({ uuid, token })

  const [state, setState] = useState<'success' | 'error' | 'loading'>('loading')

  useEffect(() => {
    try {
      if (isError) {
        showNotification(t('page.activation.error_message'), 'error')
        setState('error')
      }

      if (!isLoading && isSuccess) {
        showNotification(t('page.activation.success_message'), 'success')
        setState('success')
      }
    } catch (err: any) {
      console.error(err)

      showNotification(t('page.activation.api_error_message'), 'error')
      setState('error')
    }
  }, [isError, isLoading, isSuccess])

  const tPage = useMemo(() => {
    const data = i18n.store.data
    const lang = i18n.language
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const page = data[lang].translation?.page?.account?.validation?.[state]

    return page
  }, [state])

  const { title, subtitle, content, buttonGroup } = tPage || {}

  if (isLoading) {
    return (
      <Section size="small">
        <Row>
          <Col xs={12} display="flex" justifyContent="center">
            <LoadingSpinner />
          </Col>
        </Row>
      </Section>
    )
  }

  return (
    <Section size="small">
      <Row>
        <Col xs={12}>
          {title && <Typography variant="h4">{title}</Typography>}

          {subtitle && (
            <Typography variant="body1" fontWeight="semi-bold" mt={3}>
              {subtitle}
            </Typography>
          )}

          {content && (
            <Typography variant="body1" mt={3}>
              {content}
            </Typography>
          )}
        </Col>

        {buttonGroup && (
          <Col xs={12} display="flex" gap={2} mt={3}>
            {buttonGroup?.map((button: any) => (
              <Button
                key={button.id}
                variant={button.variant}
                color={button.color}
                href={button.href}
              >
                {button.label}
              </Button>
            ))}
          </Col>
        )}
      </Row>
    </Section>
  )
}

export default ValidationPage
