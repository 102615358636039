import { styled, Theme } from '@mui/material/styles'
import { useMemo } from 'react'
import Col from '~/components/UI/Grid/Col'

const COLUMNS = 13

type GridColumnProps = { index?: number; span?: number; itemsPerRow?: number }
// Function to calculate grid column for each child based on its index
const getGridColumn = ({ index = 0, span = COLUMNS }: GridColumnProps) => {
  const baseColumn = Math.floor(COLUMNS / span) - 1
  // md spam 5, odd starts at column 2 even at column 8
  const medium = index % 2 ? `8 / span ${baseColumn}` : `2 / span ${baseColumn}`
  // lg span  starts at column 2, 6, or 10 for the three items per row with a 1-column gap
  const large =
    index % 3 === 0
      ? `2 / span ${span}`
      : index % 3 === 1
        ? `6 / span ${span}`
        : `10 / span ${span}`

  return {
    medium,
    large,
  }
}

const getGridColumnStyles = (count: number, theme: Theme) => {
  return [...Array(count)].reduce(
    (acc, _, index) => ({
      ...acc,
      gridColumn: `auto / span ${COLUMNS}`,

      [`&:nth-of-type(${index + 1})`]: {
        [theme.breakpoints.up('md')]: {
          gridColumn: getGridColumn({ index, span: 2 }).medium,
        },

        [theme.breakpoints.up('xl')]: {
          gridColumn: getGridColumn({ index, span: 3 }).large,
        },
      },
    }),
    {},
  )
}

export const ColGrid = styled(Col, {
  shouldForwardProp: (prop: string) => !['count'].includes(prop),
})<{ count: number }>(({ theme, count }) => {
  // Use a dynamic function to set the grid column
  const gridColumnStyles = useMemo(() => getGridColumnStyles(count, theme), [count, theme])

  return {
    display: 'grid',
    gridTemplateColumns: `repeat(${COLUMNS}, 1fr)`, // 12 equal columns
    gap: theme.spacing(2), // Adjust gap as needed

    '> *': gridColumnStyles,
  }
})
