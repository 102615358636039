import 'react-big-calendar/lib/css/react-big-calendar.css'
import { I18nextProvider } from 'react-i18next'
import { RouterProvider } from 'react-router-dom'
import i18n from './i18n'
import router from './routes'

const App = () => (
  <I18nextProvider i18n={i18n}>
    <RouterProvider router={router} />
  </I18nextProvider>
)

export default App
