import { configureStore } from '@reduxjs/toolkit'

import { apiSlice } from './features/api/api-slice'
import authSlice from './features/auth/auth-slice'
// import categoriesSlice from "./features/categories/categories-slice";
import authMiddleware from './features/auth/auth-middleware'
import cookiesMiddleware from './features/cookies/cookies-middleware'
import cookiesSlice from './features/cookies/cookies-slice'
import navigationSlice from './features/navigation/navigation-slice'
// import logger from "redux-logger";

export const store = configureStore({
  reducer: {
    navigation: navigationSlice.reducer,
    // categories: categoriesSlice.reducer,
    auth: authSlice.reducer,
    cookies: cookiesSlice.reducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      // logger,
      apiSlice.middleware,
      cookiesMiddleware,
      authMiddleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
