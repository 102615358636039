import CalendarPage from '~/pages/management/Calendar'
import ClientsPage from '~/pages/management/Clients'
import FavoritesPage from '~/pages/management/Favorites'
import EditStorePostPage from '~/pages/management/post/EditPost'
import NewStorePostPage from '~/pages/management/post/NewStorePost'
import UserPostsPage from '~/pages/management/post/Posts'

export const managementRoutes = [
  {
    path: 'management',
    children: [
      { path: 'favorites', element: <FavoritesPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'clients', element: <ClientsPage /> },
      {
        path: 'posts',
        children: [
          { index: true, element: <UserPostsPage /> },
          { path: 'new', element: <NewStorePostPage /> },
          { path: ':id/edit', element: <EditStorePostPage /> },
        ],
      },
    ],
  },
]
