'use client'

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

export interface ContextType {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const Context = createContext<ContextType | undefined>(undefined)

const NavigationDrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false)

  const value = useMemo(() => ({ open, setOpen }), [open, setOpen])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default NavigationDrawerProvider

export const useNavigationDrawer = (): ContextType => {
  const context = useContext(Context)

  if (!context) {
    throw new Error('useNavigationDrawer must be used within a NavigationDrawerProvider')
  }
  return context
}
