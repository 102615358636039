import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button, DialogActions, Typography } from '@mui/material'
import { useGetBookingQuery } from '../../redux/features/api/booking-api-slice'
import FullScreenOverlay from '../UI/FullScreenOverlay'
import { useNotification } from '../UI/NotificationProvider'

interface BookingCreatedModalProps {
  bookingId: number
  open: boolean
  postId: number
  closeModal: () => void
}

const BookingCreatedModal = ({ bookingId, postId, closeModal, open }: BookingCreatedModalProps) => {
  const { showNotification } = useNotification()
  const getBookingQuery = useGetBookingQuery({ bookingId })

  const handleCloseModal = () => {
    closeModal()
  }

  if (getBookingQuery.error) {
    showNotification('Oops! There was an error while booking the service.', 'error')
    closeModal()
  }

  if (getBookingQuery.isLoading) {
    return <FullScreenOverlay />
  }

  if (getBookingQuery.isSuccess && getBookingQuery.data) {
    const { post, start_datetime: startDatetime, provider } = getBookingQuery.data
    return (
      <Dialog fullWidth={open} maxWidth={'lg'} open={true} onClose={handleCloseModal}>
        <DialogTitle>Booking Created!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your booking for <b>{post.title}</b> on <b>{startDatetime}</b> with{' '}
            <b>{provider.first_name}</b> has been created successfully.
          </Typography>
          <Typography variant="body2">
            We’ve received your appointment request and are currently double-checking our
            availabilities.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return null
}

export default BookingCreatedModal
