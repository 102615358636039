import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { config } from "../../config/api-config";
import { RootState } from "../store";
import { authActions, AuthState } from "../features/auth/auth-slice";
import { navigationActions } from "../features/navigation/navigation-slice";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: config.config.url,
  prepareHeaders(headers, { getState }) {
    const state = getState() as RootState;
    const token = state.auth.access;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  /* if expired or invalid */
  if (result.error?.status === 401) {
    /* refresh the token */
    const refreshResult = await baseQuery(
      {
        url: "/auth/token/refresh/",
        method: "POST",
        body: { refresh: (api.getState() as RootState).auth.refresh },
      },
      api,
      extraOptions
    );

    /* success in refresh */
    if (refreshResult.meta?.response?.status === 200 && refreshResult?.data) {
      const newAccess = (refreshResult?.data as AuthState).access as string;
      const newRefresh = (refreshResult?.data as AuthState).refresh as string;
      const newAccountDetail = (refreshResult?.data as AuthState).account_detail as any;

      api.dispatch(
        authActions.setCredentials({
          access: newAccess,
          refresh: newRefresh,
          account_detail: newAccountDetail,
        })
      );

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(authActions.reset());
      api.dispatch(navigationActions.setIntendedRoute(null));
    }
  }
  // if ((result.error?.data as any)?.message === "You are not logged in") {
  //   if (!mutex.isLocked()) {
  //     const release = await mutex.acquire();

  //     try {
  //       const refreshResult = await baseQuery(
  //         { credentials: "include", url: "auth/refresh" },
  //         api,
  //         extraOptions
  //       );

  //       if (refreshResult.data) {
  //         // Retry the initial query
  //         result = await baseQuery(args, api, extraOptions);
  //       } else {
  //         api.dispatch(logout());
  //         window.location.href = "/login";
  //       }
  //     } finally {
  //       // release must be called once the mutex should be released again.
  //       release();
  //     }
  //   } else {
  //     // wait until the mutex is available without locking it
  //     await mutex.waitForUnlock();
  //     result = await baseQuery(args, api, extraOptions);
  //   }
  // }

  return result;
};

export default customFetchBase;
