import { FC } from 'react'
import Header from '~/components/Header'
import { Prettify } from '~/helpers/types/app'

type HeaderLayoutProps = Prettify<{}>

const HeaderLayout: FC<HeaderLayoutProps> = ({ ...props }) => {
  return <Header />
}

export default HeaderLayout
