import { ContactMail, Info, List, Login, Logout } from '@mui/icons-material'

const header = {
  menu: [
    { to: '/posts', label: 'ui.navigation.services', icon: List },
    { to: '/about', label: 'ui.navigation.about', icon: Info },
    { to: '/contact-us', label: 'ui.navigation.contactUs', icon: ContactMail },
  ] as const,
  auth: [
    { to: '/login', label: 'ui.button.signIn', variant: 'text', icon: Login },
    { to: '/register', label: 'ui.button.signUp', variant: 'outlined', icon: Logout },
  ] as const,
} as const

export default header
