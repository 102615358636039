// FormContactUs.tsx
import { Button, Typography } from '@mui/material'
import { t } from 'i18next'
import { FC } from 'react'
import { SubmitHandler } from 'react-hook-form'
import Form, { FormField, GroupFormField } from '~/components/Form'
import Col from '~/components/UI/Grid/Col'
import Row from '~/components/UI/Grid/Row'
import Section from '~/components/UI/Section'
import { useContactForm } from './useContactForm'
import { useZodContactUs, UseZodContactUsSchema } from './useZodContactUs'

const FormContactUs: FC = () => {
  const { defaultValues } = useContactForm()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useZodContactUs(defaultValues)

  const onSubmit: SubmitHandler<UseZodContactUsSchema> = async (data) => {
    console.log(data)
    // Handle form submission here
  }

  return (
    <Section size="small">
      <Row>
        <Col xs={12}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t('page.contactUs.title')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {t('page.contactUs.description')}
          </Typography>
        </Col>

        <Col xs={12}>
          <Form noValidate onSubmit={handleSubmit(onSubmit)} register={register}>
            <GroupFormField>
              <FormField
                name="name"
                label={t('ui.form.name')}
                register={register}
                errors={errors}
                required
              />

              <FormField
                name="email"
                label={t('ui.form.email')}
                register={register}
                errors={errors}
                required
              />
            </GroupFormField>

            <FormField
              name="subject"
              label={t('ui.form.subject')}
              register={register}
              errors={errors}
              required
            />

            <FormField
              name="message"
              label={t('ui.form.message')}
              register={register}
              errors={errors}
              required
              multiline
              rows={4}
              type="textArea"
            />

            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              {t('ui.button.submit')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Section>
  )
}

export default FormContactUs
