const t = {
  account_type: 'Account Type',
  address: 'Adresse',
  avatar: 'Avatar',
  certificate: 'Certificat',
  changePassword: 'Changer le mot de passe',
  choose_file: 'Choisir un fichier',
  confirm_password: 'Confirmer le mot de passe',
  description: 'Description',
  duration: 'Durée',
  email: 'E-mail',
  fieldInvalid: '{{value}} invalide',
  fieldRequired: '{{value}} est requis',
  first_name: 'Prénom',
  last_name: 'Nom',
  latitude: 'Latitude',
  longitude: 'Longitude',
  message: 'Message',
  name: 'Nom',
  onlyNumbers: 'Seuls les chiffres sont acceptés',
  password_min_length: 'Le mot de passe doit contenir au moins 8 caractères',
  password: 'Mot de passe',
  password2: 'Mot de passe',
  passwordsDontMatch: 'Les mots de passe ne correspondent pas',
  pickLocation: 'Choisissez la localisation de votre service...',
  registration: {
    error: {
      message: "L'inscription n'a pas pu être complétée.",
      api_message:
        "Nous avons rencontré un problème lors de l'inscription. Veuillez réessayer plus tard.",
    },
    success: {
      message:
        'Succès ! Un e-mail de vérification a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception pour activer votre compte.',
    },
  },

  price: 'Prix',
  reset: { password: 'Mot de passe oublié ?' },
  save: 'Enregistrer',
  subject: 'Sujet',
  title: 'Titre',
}

export default t
