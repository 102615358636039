import { Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import EditPostForm from '../../../components/Posts/EditPostForm'
import LoadingSpinner from '../../../components/UI/LoadingSpinner'
import { useGetManagementPostQuery } from '../../../redux/features/api/management-api-slice'

const EditPostPage = () => {
  const { id } = useParams()
  // const categories = useAppSelector((state) => state.categories.categories);
  const postQuery = useGetManagementPostQuery({ id: +id! })

  const isLoading = postQuery.isLoading
  const isSuccess = !isLoading && postQuery.isSuccess
  const isError = postQuery.isError

  return (
    <Container>
      {isLoading && <LoadingSpinner />}
      {isSuccess && (
        <EditPostForm
          post={postQuery.data}
          // categories={categories}
          // storeOptions={storeOptions}
        />
      )}
      {isError && <h1>Error</h1>}
    </Container>
  )
}

export default EditPostPage
