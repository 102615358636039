import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Logout from '@mui/icons-material/Logout'
import { ListItemAvatar, Tooltip } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _mockHeader from '~/_mock/header'
import _mockManagement from '~/_mock/management'
import _mockProfile from '~/_mock/myProfile'
import UserAvatar from '~/components/UserAvatar'
import translationLink from '~/helpers/utils/translationLink'
import { useNavigationDrawer } from '~/providers/NavigationDrawer'
import { authActions } from '~/redux/features/auth/auth-slice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { Drawer } from './NavigationDrawer.styled'
import { NavigationDrawerProps } from './NavigationDrawer.types'

const NavigationDrawer: FC<NavigationDrawerProps> = ({ isProtectedPage }) => {
  const dispatch = useAppDispatch()
  const { open, setOpen } = useNavigationDrawer()

  const { authenticated: isAuthenticated, account_detail: session } = useAppSelector(
    (state) => state.auth,
  )

  const variant = useMemo(
    () => (!isProtectedPage && open ? 'temporary' : 'permanent'),
    [isProtectedPage, open],
  )

  const { t } = useTranslation()
  const auth = _mockHeader.auth
  const header = _mockHeader.menu
  const profile = _mockProfile.filter((p) => p?.to !== '/account')
  const management = _mockManagement

  const handleDrawerToggle = () => setOpen((prev) => !prev)
  const onClose = () => setOpen(false)
  const handleSignOut = () => dispatch(authActions.reset())

  return (
    <>
      <Box
        component={Backdrop}
        open={open}
        onClick={handleDrawerToggle}
        sx={(theme) => ({ zIndex: theme.zIndex.drawer })}
      />

      <Drawer
        isProtectedPage={isProtectedPage}
        variant={variant}
        open={open}
        onClose={handleDrawerToggle}
      >
        <Toolbar />

        <Box component="span" sx={{ display: { lg: 'none' } }}>
          <List>
            {header?.map(({ to, label, icon }) => (
              <CustomListItem key={to} to={to} primary={label} icon={icon} onClick={onClose} />
            ))}
          </List>

          <Divider />
        </Box>

        <Box component="span" sx={{ display: isAuthenticated ? 'block' : 'none' }}>
          <List>
            {[...management, ...profile]?.map(({ to, label, icon: IconKey }) => (
              <CustomListItem key={to} to={to} primary={label} icon={IconKey} onClick={onClose} />
            ))}
          </List>

          <Divider />
        </Box>

        <Box component="span" sx={{ display: isAuthenticated ? 'none' : 'block' }}>
          <List>
            {auth?.map(({ label, to, icon: IconKey }) => (
              <CustomListItem key={to} to={to} primary={label} icon={IconKey} onClick={onClose} />
            ))}
          </List>
        </Box>

        <Box
          component="span"
          sx={{ display: isAuthenticated && session?.email ? 'block' : 'none' }}
        >
          <List>
            <CustomListItem
              to="/account"
              onClick={onClose}
              avatar={session?.avatar}
              primary={(session?.first_name + ' ' + session?.last_name).trim() ?? ''}
              secondary={session?.email}
            />

            <CustomListItem
              to="/"
              primary={t('ui.button.signOut')}
              icon={Logout}
              onClick={handleSignOut}
            />
          </List>
        </Box>

        <Divider sx={{ mt: 'auto' }} />
        <List>
          <ListItemButton onClick={handleDrawerToggle}>
            {open ? <ChevronLeft /> : <ChevronRight />}
          </ListItemButton>
        </List>
      </Drawer>
    </>
  )
}

export default NavigationDrawer

function CustomListItem({
  avatar,
  icon: IconKey,
  onClick,
  primary,
  secondary,
  to,
}: {
  avatar?: string
  icon?: any
  onClick?: () => void
  primary: string
  secondary?: string
  to?: string
}) {
  const { t } = useTranslation()

  return (
    <Tooltip key={to} title={t(!(primary && avatar) ? primary : 'ui.profile')} placement="right">
      <ListItem disablePadding>
        <ListItemButton component={Link} to={translationLink(to ?? '')} onClick={onClick}>
          <ListItemIcon sx={{ display: IconKey ? 'inline-flex' : 'none' }}>
            {IconKey && <IconKey />}
          </ListItemIcon>

          <ListItemAvatar sx={{ display: primary && avatar ? 'inline-flex' : 'none' }}>
            <UserAvatar />
          </ListItemAvatar>

          <ListItemText primary={t(primary)} secondary={secondary} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  )
}
