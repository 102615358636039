const profile = {
  calendar: 'Calendrier',
  chat: 'Chat',
  clients: 'Clients',
  dashboard: 'Tableau de bord',
  favorites: 'Favoris',
  posts: 'Posts',
  account: 'Profil',
}

export default profile
