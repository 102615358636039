import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { GivenEvent } from "../../../redux/features/api/booking-api-slice";
import { colors } from "./Calendar";
import CalendarDialogContent from "./CalendarDialogContent";

const CalendarDialog = ({
  open,
  handleClose,
  handleAccept,
  handleReject,
  selectedEvent,
  fullScreenDialog,
  colors,
}: {
  open: boolean;
  handleClose: () => void;
  handleAccept: () => void;
  handleReject: () => void;
  selectedEvent: GivenEvent;
  fullScreenDialog: boolean;
  colors: colors;
}) => {
  const button1TextPicker = {
    in_progress: "Accept",
    accepted: "Complete",
  };

  const button2TextPicker = {
    in_progress: "Reject",
    accepted: "Cancel",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreenDialog}
      PaperProps={{
        style: {
          borderRadius: "16px",
          backgroundColor: "background.paper",
          justifyContent: "space-between",
          minWidth: "600px",
          minHeight: "400px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: colors.backgroundColor,
          color: colors.color,
          mb: 2,
        }}
      >
        {selectedEvent && selectedEvent.title
          ? selectedEvent.title
          : "Untitled Event"}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: colors.color,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CalendarDialogContent
          start={selectedEvent.start}
          price={selectedEvent?.price}
          unit={selectedEvent?.unit}
          location={selectedEvent?.location}
          status={selectedEvent?.status}
          clientName={selectedEvent?.client_name}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        {(selectedEvent.status === "in_progress" ||
          selectedEvent.status === "accepted") && (
          <>
            <Button onClick={handleReject} variant="outlined">
              {button2TextPicker[selectedEvent.status]}
            </Button>
            <Button onClick={handleAccept} variant="contained">
              {button1TextPicker[selectedEvent.status]}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CalendarDialog;
