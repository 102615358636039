import { Box, Grid } from "@mui/material";

import InputImageCrop from "../UI/InputImageCrop";

const PostImageGalleryForm = ({
  startingImages,
  images,
  setImages,
  resetImages,
}: {
  startingImages?: { image: string; id: number }[];
  images: { index: number; file?: File }[];
  setImages: (index: number, file?: File) => void;
  resetImages?: (imageId: number) => void;
}) => {
  return (
    <Grid container spacing={{ xs: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
      {images.map((elm) => (
        <Grid item xs={12} sm={6} md={3} key={elm.index}>
          <Box position="relative" display="block" paddingBottom="100%">
            <Box
              position="absolute"
              left={0}
              top={0}
              width="100%"
              height="100%"
              sx={{ objectFit: "scale-down" }}
            >
              <InputImageCrop
                startingValue={
                  startingImages && startingImages.length > elm.index
                    ? startingImages[elm.index].image
                    : undefined
                }
                aspect={undefined}
                setImageResetCallback={() => {
                  setImages(elm.index, undefined);
                  if (startingImages && startingImages.length > elm.index) {
                    resetImages!(startingImages[elm.index].id);
                  }
                }}
                setImageCallback={(file) => {
                  setImages(elm.index, file);
                }}
              />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default PostImageGalleryForm;
