import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

type AppBarProps = MuiAppBarProps & {
  open?: boolean
  drawerWidth?: number
}

/**
 * Customized AppBar component with additional styling and props.
 *
 * @param {MuiAppBarProps & { open?: boolean; drawerWidth?: number }} props - Props for the AppBar component.
 * @returns {React.ComponentType<AppBarProps>} - The styled AppBar component.
 */
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: string) => !['open', 'drawerWidth'].includes(prop),
})<AppBarProps>(({ theme, open = false, drawerWidth = 0 }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const ButtonLogo = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
}))

export const MainNav = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}))
