import { Box, LinearProgress } from "@mui/material";

const Saving = ({
  variant = "indeterminate",
}: {
  variant?: "buffer" | "determinate" | "indeterminate" | "query";
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress variant={variant} />
    </Box>
  );
};

export default Saving;
