import { Box, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Col, Row } from '~/components/UI/Grid'
import Section from '~/components/UI/Section'
import { MediaProps } from './Media.types'
import MediaImage from './MediaImage'

const Media: FC<PropsWithChildren<MediaProps>> = ({
  alignContent,
  title,
  description,
  media,
  ...section
}) => (
  <Section {...section}>
    {'image' === media?.type && (
      <Row>
        {(title || description) && (
          <Col xs={12} mb={3}>
            {title && (
              <Typography variant="h4" textAlign={alignContent} mb={2}>
                {title}
              </Typography>
            )}

            {description && (
              <Typography variant="body1" textAlign={alignContent}>
                {description}
              </Typography>
            )}
          </Col>
        )}

        <Col xs={12}>
          <Box sx={{ borderRadius: 1, overflow: 'clip', aspectRatio: '3 / 2' }}>
            <MediaImage {...media} sx={{ position: 'absolute', top: '50%', translate: '-50% 0' }} />
          </Box>
        </Col>
      </Row>
    )}
  </Section>
)

export default Media
