import CameraIcon from '@mui/icons-material/Camera'
import ClearIcon from '@mui/icons-material/Clear'
import CropIcon from '@mui/icons-material/Crop'
import SaveIcon from '@mui/icons-material/Save'
import { Box, IconButton, Theme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactCrop, { PixelCrop, centerCrop, makeAspectCrop, type Crop } from 'react-image-crop'
import { canvas } from '~/helpers/utils/canvas'
import styles from './InputImageCrop.module.css'
import LoadingSpinner from './LoadingSpinner'
import Saving from './Saving'

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
const STATUS = {
  PRISTINE: 'PRISTINE',
  CROPPING: 'CROPPING',
  LOADING_IMAGE: 'LOADING_IMAGE',
  SAVED: 'SAVED',
  SAVING_IMAGE: 'SAVING_IMAGE',
}
const scopeStyles = (theme: Theme) => ({
  button: { border: 3, borderRadius: '50%', padding: 1 },
  mainWrapper: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 1,
    minHeight: '200px',
  },
  inputIcon: {
    position: 'absolute',
    color: theme.palette.primary.dark,
  },
})
const fetchImage = async (imageUrl: string, callback: (...args: any) => any) => {
  try {
    const response = await fetch(imageUrl)
    if (response.ok) {
      const dataUrl = await response.blob()
      const reader = new FileReader()

      reader.onload = () => {
        callback(reader.result?.toString() || '')
      }

      reader.readAsDataURL(dataUrl)
    } else {
      console.error('Failed to fetch image')
    }
  } catch (error) {
    console.error('Error fetching image:', error)
  }
}
const InputImageCrop = ({
  startingValue,
  setImageCallback,
  setImageResetCallback,
  aspect,
}: {
  startingValue?: string
  aspect?: number
  setImageResetCallback: () => void | undefined
  setImageCallback: (file: File) => void | undefined
}) => {
  const [status, setStatus] = useState(startingValue ? STATUS.SAVED : STATUS.PRISTINE)
  const [imgSource, setImgSource] = useState('')

  useEffect(() => {
    if (startingValue) {
      fetchImage(startingValue, setImgSource)
    }
  }, [startingValue])

  const [imgPreviewSource, setImgPreviewSource] = useState(startingValue)
  const [crop, setCrop] = useState<Crop>()
  const [currentCrop, setCurrentCrop] = useState<PixelCrop>()
  const imgRef = useRef<HTMLImageElement>(null)
  const imgPreviewRef = useRef<HTMLImageElement>(null)

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setStatus(STATUS.LOADING_IMAGE)

      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()

      reader.onload = () => {
        setStatus(STATUS.CROPPING)
        return setImgSource(reader.result?.toString() || '')
      }

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspect ? aspect : width / height))
  }

  const resetView = () => {
    setStatus(STATUS.PRISTINE)
    setImgSource('')
    setImgPreviewSource(undefined)
    setImageResetCallback()
  }

  const editImage = () => {
    setStatus(STATUS.CROPPING)
  }

  const saveImage = () => {
    if (imgRef.current && currentCrop) {
      setStatus(STATUS.SAVING_IMAGE)

      const myCanvas = document.createElement('canvas')

      const canvasElement = canvas.setCanvasImage(imgRef.current, myCanvas, currentCrop)

      if (canvasElement) {
        const file: File = canvas.getFileFromCanvas(canvasElement)
        setImageCallback(file)
        setPreviewImage(file)
        setStatus(STATUS.SAVED)
      }
    }
  }

  const setPreviewImage = (file: File) => {
    const reader = new FileReader()
    reader.onload = () => {
      setImgPreviewSource(reader.result?.toString() || '')
    }
    reader.readAsDataURL(file)
  }

  const inputElement = (
    <React.Fragment>
      {status === STATUS.PRISTINE && (
        <Box className={styles['input-wrapper']}>
          <Box className={styles['input-icon-wrapper']}>
            <CameraIcon fontSize="large" sx={(theme) => scopeStyles(theme).inputIcon} />
          </Box>
          <input
            type="file"
            accept="image/heic, image/png, image/jpeg, image/webp"
            onChange={onSelectFile}
          />
        </Box>
      )}
    </React.Fragment>
  )

  const saveButton = (
    <IconButton aria-label="save" color="success" onClick={saveImage}>
      <SaveIcon fontSize="large" sx={(theme) => scopeStyles(theme).button} />
    </IconButton>
  )

  const resetButton = (
    <IconButton aria-label="reset" color="error" onClick={resetView}>
      <ClearIcon fontSize="large" sx={(theme) => scopeStyles(theme).button} />
    </IconButton>
  )

  const cropAddon = (
    <Box className={styles['addon-wrapper']}>
      {saveButton} {resetButton}
    </Box>
  )

  const savedElement = (
    <React.Fragment>
      <Box className={styles['saved-wrapper']}>
        <Box>
          <IconButton aria-label="edit" color="info" onClick={editImage} sx={{ cursor: 'pointer' }}>
            <CropIcon fontSize="medium" sx={(theme) => scopeStyles(theme).button} />
          </IconButton>
          <IconButton
            aria-label="reset"
            color="error"
            onClick={resetView}
            sx={{ cursor: 'pointer' }}
          >
            <ClearIcon fontSize="medium" sx={(theme) => scopeStyles(theme).button} />
          </IconButton>
        </Box>
      </Box>
      <img ref={imgPreviewRef} alt="preview" className={styles.preview} src={imgPreviewSource} />
    </React.Fragment>
  )

  const showSavedImage =
    status === STATUS.SAVED || (status === STATUS.CROPPING && imgPreviewSource !== '')

  const showDialog = status === STATUS.CROPPING || status === STATUS.LOADING_IMAGE

  return (
    <Box sx={(theme) => scopeStyles(theme).mainWrapper} className={styles['main-wrapper']}>
      {status === STATUS.PRISTINE && inputElement}
      {status === STATUS.SAVING_IMAGE && <Saving />}

      {showSavedImage && savedElement}

      {showDialog && (
        <Box className={styles['dialog-wrapper']} display="flex" justifyContent="center">
          {status === STATUS.CROPPING && (
            <ReactCrop
              style={{ maxHeight: '60vh' }}
              renderSelectionAddon={() => cropAddon}
              keepSelection={true}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCurrentCrop(c)}
              aspect={aspect}
              className={styles['crop-wrapper']}
            >
              <img ref={imgRef} alt="Crop me" src={imgSource} onLoad={onImageLoad} />
            </ReactCrop>
          )}
          {(status === STATUS.LOADING_IMAGE || status === STATUS.SAVING_IMAGE) && (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <LoadingSpinner size={250} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default InputImageCrop
