import MuiSkeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Image from '~/components/Image'
import { Agenda, Container, Details, Media } from './Practitioner.styled'
import { PractitionerProps } from './Practitioner.styles'

// Usage in a component
const Practitioner: FC<PractitionerProps> = ({
  id,
  media,
  details,
  agenda,
  description,
  ...props
}) => {
  const { t } = useTranslation()

  media = { src: props.thumbnail, alt: props.title }

  return (
    <>
      {(description || details) && (
        <Container elevation={3}>
          <Media>
            <Image {...media} />
          </Media>

          <Details>
            <Typography variant="h5">{t('practitioner_name')}</Typography>

            <Typography>{description || details}</Typography>
          </Details>

          <Agenda display="flex" justifyContent="center">
            <Typography variant="h5">{t('availability')}</Typography>

            <Typography>{agenda}</Typography>
          </Agenda>
        </Container>
      )}
    </>
  )
}

const Skeleton: FC = () => (
  <Container elevation={3}>
    <Media>
      <MuiSkeleton variant="rectangular" width="stretch" height="100%"></MuiSkeleton>
    </Media>

    <Details>
      <MuiSkeleton variant="text" width="stretch">
        <Typography variant="h4">.</Typography>
      </MuiSkeleton>

      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
    </Details>

    <Agenda>
      <MuiSkeleton variant="text" width="stretch">
        <Typography variant="h4">.</Typography>
      </MuiSkeleton>

      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
      <MuiSkeleton variant="text" width="stretch">
        <Typography>.</Typography>
      </MuiSkeleton>
    </Agenda>
  </Container>
)

export default Object.assign(Practitioner, { Skeleton })
