import page from './page'
import ui from './ui'

const translation = {
  book_now: 'Réserver maintenant',
  i_accept: "J'accepte",
  refuse: 'Refuser',
  select_language: 'Sélectionner la langue',
  create: 'créer',
  update: 'mettre à jour',
  delete: 'supprimer',
  user_type_normal: 'Client',
  user_type_store_owner: 'Fournisseur de services',
  sign_up: "S'inscrire",
  login: 'Se connecter',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  email_address: 'Adresse e-mail',
  password: 'Mot de passe',
  'problem_on_{{value}}_message': 'Problème sur {{value}} !',
  confirm_password: 'Confirmer le mot de passe',
  '{{value}}_is_required': '{{value}} est requis',
  practitioner_name: 'Nom du praticien',
  availability: 'Disponibilité',

  ui,
  page,
}

export default translation
