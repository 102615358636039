import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import 'leaflet/dist/leaflet.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-image-crop/dist/ReactCrop.css'
import App from './App'
import Providers from './providers'
import reportWebVitals from './reportWebVitals'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCAIE8zSuASceH7xBhvWTf_y4acpWF-cWg',
  authDomain: 'given-pt.firebaseapp.com',
  projectId: 'given-pt',
  storageBucket: 'given-pt.appspot.com',
  messagingSenderId: '81722281189',
  appId: '1:81722281189:web:17706c487ae723d217180c',
  measurementId: 'G-GKH3L2QV9D',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app)

// store.dispatch(loadCategories())

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Providers>
        <App />
      </Providers>
    </LocalizationProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
