const about = {
  title: 'About Us',
  description:
    'We are a group of passionate people who are looking to make a difference in the world. We are a group of passionate people who are looking to make a difference in the world.',
  team: {
    title: 'Our Team',
    description:
      'We are a group of passionate people who are looking to make a difference in the world. We are a group of passionate people who are looking to make a difference in the world.',
  },
}

export default about
