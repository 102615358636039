const cookies = {
  accept_all: 'Accept All',
  accept_required: 'Accept Required',
  always_active: 'Always active',
  description:
    "We don't have traditional offerings, but we do provide top-notch Booking online services. To ensure your experience is as smooth as possible, we employ technologies for storing and accessing device information. By agreeing to these technologies, we may process data such as browsing behavior or unique identifiers on this website. If you choose not to give or withdraw your consent, certain features and functions may be affected.",
  message: {
    functional:
      'The utilization of technical storage or access is imperative when it comes to fulfilling the legitimate objective of facilitating the use of a particular service that has been explicitly requested by the subscriber or user, or for the exclusive purpose of transmitting a message across an electronic communications network.',
    marketing:
      'Technical storage or access is essential for the establishment of user profiles, the delivery of advertisements, or for monitoring user activity on a website or across various websites to achieve similar marketing objectives.',
  },
  save_settings: 'Save Settings',
  title: 'Manage cookie consent',
  types: { functional: 'Functional', marketing: 'Marketing' },
  view_settings: 'View Settings',
}

export default cookies
