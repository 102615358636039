const data = [
  {
    id: 'ivb204b',
    title: undefined,
    description: undefined,
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
  {
    id: 'dvjn0b2b',
    title: undefined,
    description: undefined,
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
  {
    id: 'sodkvjnb',
    title: undefined,
    description: undefined,
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
] as const

export default data
