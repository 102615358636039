import images from '~/_mock/images'
import FormNewsletter from '~/components/FormNewsletter'
import HeroCarousel from '~/components/HeroCarousel/HeroCarousel'
import HowItWorks from '~/components/Layout/HowItWorks/HowItWorks'
import Testimonials from '~/components/Layout/Testimonials'
import Media from '~/components/Media'

const HomePage = () => {
  const { urls, alt_description: alt } = { ...images?.[2] }

  return (
    <>
      <HeroCarousel />

      <HowItWorks />

      <Media
        alignContent="center"
        title="the crazy fox jumped over the lazy dog"
        description="the crazy fox jumped over the lazy dog"
        size="small"
        media={{
          type: 'image',
          item: [{ src: urls?.regular, alt }],
        }}
      />

      <Testimonials />

      <FormNewsletter />
    </>
  )
}

export default HomePage
