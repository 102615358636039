const data = [
  {
    id: 'ivb204b',
    title: '<NAME>',
    description: '<DESCRIPTION>',
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
  {
    id: 'dvjn0b2b',
    title: 'Mary Jane',
    description: 'CEO',
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
  {
    id: 'sodkvjnb',
    title: 'John Doe',
    description: 'CTO',
    media: {
      src: 'https://placehold.co/300',
      alt: '',
    },
  },
] as const

export default data
