import { Middleware } from '@reduxjs/toolkit'
import { cacheAuth, clearCachedAuth } from './auth-actions'
import { authActions } from './auth-slice'

const authMiddleware: Middleware<{}, any> =
  ({ getState }) =>
  (next) =>
  (action: any) => {
    const result = next(action) // Pass the action through to the next middleware or reducers

    if (action.type === authActions.setCredentials.type) {
      const { access, account_detail: accountDetail, refresh } = action.payload
      cacheAuth(access, refresh, accountDetail)
    } else if (action.type === authActions.reset.type) {
      clearCachedAuth()
    }

    return result // Return the result of processing the action
  }

export default authMiddleware
