import { Typography } from '@mui/material'
import { useRouteError } from 'react-router-dom'
import Section from '~/components/UI/Section'

const ErrorPage = () => {
  const error = useRouteError() as { status: number; data: any }

  console.error({ error })

  let title = 'An error occurred!'
  let message = 'Something went wrong!'

  if (error.status === 500) {
    message = error.data.message
  }

  if ([404, 400].includes(error.status)) {
    title = 'Not found!'
    message = error.data ? error.data : 'Could not find resource or page.'
  }
  return (
    <Section>
      <Typography variant="h4" gutterBottom>
        {title} {error.status}
      </Typography>

      <Typography variant="body1">{message}</Typography>
    </Section>
  )
}

export default ErrorPage
