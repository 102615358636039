const locale = {
  language: 'Langue',
  english: 'Anglais',
  spanish: 'Espagnol',
  french: 'Français',
  german: 'Allemand',
  italian: 'Italien',
  russian: 'Russe',
  turkish: 'Turc',
  portuguese: 'Portugais',
  arabic: 'Arabe',
  polish: 'Polonais',
  chinese: 'Chinois',
  japanese: 'Japonais',
  korean: 'Coréen',
  indonesian: 'Indonésien',
  thai: 'Thaï',
  vietnamese: 'Vietnamien',
  czech: 'Tchèque',
  dutch: 'Néerlandais',
  romanian: 'Roumain',
}

export default locale
