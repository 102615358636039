import clsx from 'clsx'
import { ForwardRefRenderFunction, PropsWithChildren, Ref, createRef, forwardRef } from 'react'
import Container from '~/components/UI/Grid/Container'
import Row from '~/components/UI/Grid/Row'
import { InnerBox, OuterBox } from './Section.styled'
import { SectionProps, getSectionPadding } from './component'

/**
 * Please use `AppSection` instead.
 *
 * @param {string} sectionClass - Deprecated! Use outerBoxProps.className instead.
 * @param {object} outerBoxProps - Has all the properties used by MuiBox.
 * @param {object} innerBoxProps - Has all the properties used by MuiBox.
 * @param {string} bg_color - The theme of the section.
 * @param {string} color - The theme of the component wrapper.
 * @param {string} size - The size of the section.
 * @param {object} padding - The padding of the section.
 * @param {boolean} intro_animation - Whether to animate the section.
 * @returns {JSX.Element} JSX element.
 */
const Section: ForwardRefRenderFunction<HTMLDivElement, PropsWithChildren<SectionProps>> = (
  {
    children,
    innerBoxProps,
    outerBoxProps,
    padding = [{ target: 'y', value: 'normal' }],
    size = 'normal',
    disableGutters,
  },
  ref: Ref<HTMLDivElement>,
) => {
  const sectionRef = ref || createRef<HTMLDivElement>()
  const targetRef = createRef<HTMLDivElement>()

  return (
    <OuterBox
      ref={sectionRef}
      component="section"
      {...outerBoxProps}
      className={clsx(outerBoxProps?.className)}
      sx={{ ...outerBoxProps?.sx }}
    >
      <Container
        disableGutters={disableGutters}
        maxWidth="xl"
        className="containerBox"
        sx={{ ...getSectionPadding(padding) }}
      >
        <Row>
          <InnerBox
            ref={targetRef}
            xs={12}
            {...('small' === size && { sm: 8, smOffset: 2 })}
            {...('normal' === size && { sm: 10, smOffset: 1 })}
            {...innerBoxProps}
            className={clsx(innerBoxProps?.className)}
          >
            {children}
          </InnerBox>
        </Row>
      </Container>
    </OuterBox>
  )
}

export default forwardRef(Section)
