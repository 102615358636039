import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { Provider } from '~/helpers/types/provider'
import { useListProvidersQuery } from '~/redux/features/api/booking-api-slice'

interface ProviderSelectProps {
  postId: number
  onProviderChange: (providerId: number) => void
}

const ProviderSelect = ({ postId, onProviderChange }: ProviderSelectProps) => {
  const [selectedProvider, setSelectedProvider] = useState<number>(-1)
  const listProvidersQuery = useListProvidersQuery({ postId })

  useEffect(() => {
    if (listProvidersQuery.isSuccess && listProvidersQuery.data?.length > 0) {
      setSelectedProvider(listProvidersQuery.data[0].user.id)
      onProviderChange(listProvidersQuery.data[0].user.id)
    }
  }, [listProvidersQuery.isSuccess, listProvidersQuery.data, onProviderChange])

  const handleProviderChange = (e: SelectChangeEvent<number>) => {
    const providerId = e.target.value as number
    setSelectedProvider(providerId)
    onProviderChange(providerId)
  }

  return (
    <FormControl fullWidth margin="dense">
      <InputLabel id="provider-select-label">Provider</InputLabel>
      <Select
        labelId="provider-select-label"
        label="Provider"
        value={selectedProvider}
        onChange={handleProviderChange}
      >
        {listProvidersQuery.data?.map((provider: Provider) => (
          <MenuItem key={provider.id} value={provider.user.id}>
            {provider.user.first_name} {provider.user.last_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ProviderSelect
