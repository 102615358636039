import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Avatar, Button, Divider, FormControl, FormHelperText, Typography } from '@mui/material'
import { t } from 'i18next'
import { SubmitHandler } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import Form, { FormField } from '~/components/Form'
import GoogleSignIn from '~/components/Google/SignIn'
import { Col, Row } from '~/components/UI/Grid'
import LoadingSpinner from '~/components/UI/LoadingSpinner'
import { useNotification } from '~/components/UI/NotificationProvider'
import { brand } from '~/config/brand-config'
import translationLink from '~/helpers/utils/translationLink'
import { useLoginMutation } from '~/redux/features/api/auth-api-slice'
import { authActions } from '~/redux/features/auth/auth-slice'
import { cookiesActions } from '~/redux/features/cookies/cookies-slice'
import { navigationActions } from '~/redux/features/navigation/navigation-slice'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { useZodLogin, UseZodLoginSchema } from './useZodLogin'

const defaultValues = {
  email: `admin@${brand.domain}`,
  password: 'password123',
}

const LoginForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const intendedRoute = useAppSelector((state) => state.navigation.intendedRoute)
  const [login, { isLoading }] = useLoginMutation()
  const { showNotification } = useNotification()

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useZodLogin(defaultValues)

  const onSubmit: SubmitHandler<UseZodLoginSchema> = async (data) => {
    try {
      const tokensData = await login(data).unwrap()

      if (tokensData.access) {
        dispatch(authActions.setCredentials({ ...tokensData }))
        dispatch(
          cookiesActions.setCookies({
            cookies: tokensData.account_detail.settings.cookie_set,
          }),
        )

        navigate(intendedRoute || '/')
        dispatch(navigationActions.setIntendedRoute(null))
        return
      }

      showNotification(t('login.error.unknown'), 'error')
    } catch (err: any) {
      console.error(err)
      const { data: errorData }: any = err
      for (const property in errorData) {
        const isDetail = property === 'detail'
        const p = (isDetail ? 'root.detail' : property) as keyof UseZodLoginSchema
        setError(p, { type: 'manual', message: errorData[property] }, { shouldFocus: !isDetail })
      }
    }
  }

  return (
    <Row>
      <Col
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1 }}>
          {!isLoading && <LockOutlinedIcon />}
          {isLoading && <LoadingSpinner />}
        </Avatar>

        <Typography component="h1" variant="h5">
          {t('ui.button.signIn')}
        </Typography>

        <FormHelperText error={Boolean(errors.root?.detail)}>
          {(errors.root?.detail && errors.root?.detail.message) ||
            (errors.root?.detail && 'Account not found!')}
        </FormHelperText>
      </Col>

      <Col xs={12}>
        <Form noValidate size="small" onSubmit={handleSubmit(onSubmit)} register={register}>
          <FormField
            autoFocus
            autoComplete="email"
            label={t('ui.form.email')}
            name="email"
            errors={errors}
            register={register}
          />

          <FormField
            type="password"
            autoComplete="current-password"
            name="password"
            label={t('password')}
            errors={errors}
            register={register}
          />

          <FormControl sx={{ mt: 2 }}>
            <Button type="submit" fullWidth variant="contained">
              {t('ui.button.signIn')}
            </Button>
          </FormControl>

          <Divider>
            <Typography variant="caption">or</Typography>
          </Divider>

          <FormControl sx={{ mt: 2 }}>
            <GoogleSignIn />
          </FormControl>

          <Row>
            <Col xs={10} xsOffset={1} justifyContent="space-between" display="flex" sx={{ mt: 2 }}>
              <Typography component="span" display="inline-flex" variant="body2">
                <Link to={translationLink('/password')}>{t('ui.forgotPassword')}</Link>
              </Typography>

              <Typography component="span" display="inline-flex" variant="body2">
                <Link to={translationLink('/register')}>{t('ui.dontHaveAccount')}</Link>
              </Typography>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default LoginForm
