import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material/";

interface ConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (postId: string) => Promise<void>;
  deleteEntityName: string;
  postId: string;
}

const DialogConfirmations: React.FC<ConfirmationDialogProps> = ({
  open,
  handleClose,
  handleConfirm,
  deleteEntityName,
  postId,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this {deleteEntityName}? This action
          cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleConfirm(postId)} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmations;
