import LoginForm from '~/components/FormLogin'
import Section from '~/components/UI/Section'

const LoginPage = () => {
  return (
    <Section size="small">
      <LoginForm />
    </Section>
  )
}
export default LoginPage
