import { Category } from '~/helpers/types/category'

const CURRENCY_CODE_SYMBOL_MAPPING: {
  [key: string]: string
} = {
  EUR: '€', // Euro (Euro)
  USD: '$', // United States Dollar (Dollar)
  ALL: 'L', // Albanian Lek (Lek)
  AMD: '֏', // Armenian Dram (Dram)
  AZN: '₼', // Azerbaijani Manat (Manat)
  BYN: 'Br', // Belarusian Ruble (Ruble)
  BAM: 'KM', // Bosnia and Herzegovina Convertible Mark (Convertible Mark)
  BGN: 'лв', // Bulgarian Lev (Lev)
  HRK: 'kn', // Croatian Kuna (Kuna)
  CZK: 'Kč', // Czech Koruna (Koruna)
  DKK: 'kr', // Danish Krone (Krone)
  GEL: '₾', // Georgian Lari (Lari)
  HUF: 'Ft', // Hungarian Forint (Forint)
  ISK: 'kr', // Icelandic Króna (Króna)
  KZT: '₸', // Kazakhstani Tenge (Tenge)
  MKD: 'ден', // Macedonian Denar (Denar)
  MDL: 'L', // Moldovan Leu (Leu)
  NOK: 'kr', // Norwegian Krone (Krone)
  PLN: 'zł', // Polish Złoty (Złoty)
  RON: 'lei', // Romanian Leu (Leu)
  RUB: '₽', // Russian Ruble (Ruble)
  RSD: 'дин.', // Serbian Dinar (Dinar)
  SEK: 'kr', // Swedish Krona (Krona)
  CHF: 'CHF', // Swiss Franc (Franc)
  TRY: '₺', // Turkish Lira (Lira)
  UAH: '₴', // Ukrainian Hryvnia (Hryvnia)
}

export const map = {
  currencyCodeToSymbol: (currencyCode: string): string => {
    return CURRENCY_CODE_SYMBOL_MAPPING[currencyCode] || '***'
  },
  categoryIdToObject: (categoryId: number, categories: Category[]): Category | null => {
    return categories.find((category) => category.id === categoryId) || null
  },
}
