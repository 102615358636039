import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PostDetail from '~/components/Posts/PostDetail'
import PostPreview from '~/components/Posts/PostPreview'
import PostDetailBoxSkeleton from '~/components/Skeleton/PostDetailBoxSkeleton'
import { Post } from '~/helpers/types/post'
import { useGetPostQuery } from '~/redux/features/api/posts-api-slice'
import { useAppSelector } from '~/redux/hooks'

const PostDetailPage = () => {
  const { id } = useParams()

  const authenticated = useAppSelector((state) => state.auth.authenticated)

  const { data: post, isLoading: postIsLoading, refetch } = useGetPostQuery({ id: id! })

  useEffect(() => {
    refetch()
  }, [authenticated, refetch])

  // const { data: relatedPosts, isLoading: relatedPostsIsLoading } =
  //   useGetPostRelatedPostsQuery({ id: id! });
  // const { data: seller, isLoading: sellerIsLoading } = useGetPostSellerQuery({
  //   id: id!,
  // });

  const { postimage_set: postPreview = [], ...postDetail } = {
    ...post,
  } as Post

  return (
    <Grid container sx={{ background: 'white', padding: '20px' }}>
      <Grid item xs={6}>
        {postIsLoading && <PostDetailBoxSkeleton />}
        {!postIsLoading && post && <PostPreview preview={postPreview} />}
      </Grid>

      <Grid item xs={6} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        {postIsLoading && <PostDetailBoxSkeleton />}
        {!postIsLoading && post && (
          <PostDetail
            key={authenticated ? 'authenticated' : 'not-authenticated'}
            postDetail={postDetail}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default PostDetailPage
