import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, TextFieldProps } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form'

type FormFieldProps<T extends FieldValues> = {
  errors: FieldErrors<T>
  label: string
  name: Path<T>
  register?: UseFormRegister<T>
  required?: boolean
  type?: string // Adding type prop to handle different input types
  clearIconButtonProps?: Omit<React.ComponentProps<typeof IconButton>, 'onClick'> // Props for the clear button
} & Omit<TextFieldProps, 'name'>

const FormField = <T extends FieldValues>({
  clearIconButtonProps,
  errors,
  fullWidth = true,
  label,
  name,
  register,
  required = false,
  sx,
  type,
  ...props
}: FormFieldProps<T>) => {
  if (!register) {
    console.warn('FormField: register must be provided')
  }

  const registerField = register ? register(name, { required }) : null

  // Determine if this is a file input
  const isFileInput = type === 'file'

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <FormControl fullWidth={fullWidth}>
      <TextField
        size="small"
        margin="normal"
        required={required}
        label={label}
        error={!!errors[name]}
        helperText={errors[name]?.message as string}
        type={showPassword ? 'text' : type}
        {...props}
        {...registerField}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <>
              {props.InputProps?.endAdornment}

              {'password' === type && (
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )}

              {clearIconButtonProps && (
                <IconButton
                  {...clearIconButtonProps}
                  onClick={() => {
                    registerField?.onChange({ target: { name, value: '' } })
                  }}
                />
              )}
            </>
          ),
          onChange: (e) => {
            if (isFileInput && e.target instanceof HTMLInputElement && e.target.files) {
              // Manually handle file input change
              registerField?.onChange({
                target: {
                  name: e.target.name,
                  value: e.target.files[0], // Set the first file as the value
                },
              })
            } else {
              // Handle regular input changes
              registerField?.onChange?.(e)
            }
          },
          sx: {
            ...props.InputProps?.sx,
            ...(isFileInput && { input: { opacity: 1 } }),
          },
        }}
      />
    </FormControl>
  )
}

export default FormField
