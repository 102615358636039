import { Grid, ImageList, ImageListItem } from '@mui/material'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Post } from '~/helpers/types/post'
import { PostImage } from '~/helpers/types/post-image'
import styles from './PostPreview.module.css'

const PreviewImage = ({
  onClick,
  options,
  detailId,
}: {
  onClick: Dispatch<SetStateAction<number>>
  options: Post['postimage_set']
  detailId: number
}) => (
  <ImageList cols={1}>
    {(options || []).map((option: PostImage) => (
      <ImageListItem
        key={option.id}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => onClick(option.id)}
      >
        <img
          srcSet={option.image}
          className={
            detailId !== option.id
              ? styles['preview-img']
              : `${styles['preview-img']} ${styles['active']}`
          }
          src={option.image}
          alt={`Thumbnail for image ${option.id}`}
          loading="lazy"
          style={{ width: '100%' }}
        />
      </ImageListItem>
    ))}
  </ImageList>
)

const PostPreview = ({ preview }: { preview: Post['postimage_set'] }) => {
  preview = preview || []

  const [detailId, setDetailId] = useState<number>(preview[0]?.id)
  const detail = useMemo(() => {
    return preview?.filter(({ id }) => detailId === id)[0]
  }, [detailId, preview])

  return (
    <Grid container>
      <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
        <PreviewImage onClick={setDetailId} options={preview} detailId={detailId} />
      </Grid>
      <Grid item xs={11} display="flex" justifyContent="center" alignItems="center">
        <img
          src={detail?.image}
          alt={`Detailed view for image ${detailId}`}
          loading="lazy"
          style={{
            width: '80%',
            height: '80%',
          }}
        />
      </Grid>
    </Grid>
  )
}

export default PostPreview
