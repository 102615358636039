const locale = {
  language: 'Language',
  english: 'English',
  spanish: 'Spanish',
  french: 'French',
  german: 'German',
  italian: 'Italian',
  russian: 'Russian',
  turkish: 'Turkish',
  portuguese: 'Portuguese',
  arabic: 'Arabic',
  polish: 'Polish',
  chinese: 'Chinese',
  japanese: 'Japanese',
  korean: 'Korean',
  indonesian: 'Indonesian',
  thai: 'Thai',
  vietnamese: 'Vietnamese',
  czech: 'Czech',
  dutch: 'Dutch',
  romanian: 'Romanian',
}

export default locale
