import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import ReusableTableWithActions, {
  FavoriteActionsMap,
} from '~/components/Table/ReusableTableWithActions'
import MapMarketIcon from '~/components/UI/MapMarketIcon'
import Section from '~/components/UI/Section'
import {
  useGetAccountFavoritesQuery,
  useToggleFavoritePostMutation,
} from '~/redux/features/api/management-api-slice'

const FavoritesPage = () => {
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 })
  const [openMap, setOpenMap] = useState(false)
  const [toggleFavoritePost] = useToggleFavoritePostMutation()
  const { data: favorites, isSuccess } = useGetAccountFavoritesQuery()
  const navigate = useNavigate()

  return (
    <Section size="big" padding={[{ target: 'top', value: 'small' }]}>
      <Paper
        elevation={3}
        sx={{
          height: (theme) => `calc(93dvh - ${theme.breakpoints.values.sm ? 64 : 56}px)`,
          borderRadius: 2,
          spacing: 2,
          padding: 3,
          backgroundColor: 'background.paper',
          ':hover': {
            boxShadow:
              '0px 3px 3px -2px rgba(0,0,0,0.8),0px 3px 4px 0px rgba(0,0,0,0.20),0px 1px 8px 0px rgba(0,0,0,0.18)',
          },
        }}
      >
        <Typography variant="h6" sx={{ color: 'text.primary', marginBottom: 3 }}>
          Favorites
        </Typography>
        <Box height={'95%'}>
          {isSuccess && favorites.length > 0 ? (
            <ReusableTableWithActions
              data={favorites.map((favorite) => {
                return {
                  preview: favorite.post.postimage_set?.slice(0, 1)[0].image,
                  title: favorite.post.title,
                  address: favorite.post.address,
                  price: favorite.post.price,
                  currency_code: favorite.post.currency_code,
                }
              })}
              actionButtons={favorites.reduce<FavoriteActionsMap>((acc, favorite) => {
                acc[favorite.post.title] = [
                  {
                    id: 'Contact',
                    onClick: () => {
                      navigate(`/management/chat/?contact=${favorite.post.admin}`)
                    },
                    Icon: ForumOutlinedIcon,
                  },
                  {
                    id: 'Map',
                    onClick: () => {
                      setMapCenter({
                        lat: favorite.post.gps_lat,
                        lng: favorite.post.gps_lng,
                      })
                      setOpenMap(true)
                    },
                    Icon: MapOutlinedIcon,
                  },
                  {
                    id: 'Unfavorite',
                    onClick: async () => {
                      const formData = new FormData()
                      formData.append('post_id', favorite.post.id.toString())

                      await toggleFavoritePost({
                        formData,
                        post_id: favorite.post.id,
                      }).unwrap()
                    },
                    Icon: DeleteOutlineOutlinedIcon,
                  },
                ]
                return acc
              }, {})}
              actionPath="dashboard"
              imageColumns={['preview']}
            />
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              No favorites yet
            </Typography>
          )}
        </Box>
        <Dialog
          open={openMap}
          onClose={() => setOpenMap(false)}
          PaperProps={{
            style: {
              borderRadius: '16px',
              backgroundColor: 'background.paper',
              justifyContent: 'space-between',
              minWidth: '600px',
              minHeight: '400px',
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
            }}
          >
            Location
            <IconButton
              aria-label="close"
              onClick={() => setOpenMap(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'primary.contrastText',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Box width="600px" height="400px" sx={{ padding: 1 }}>
            <MapContainer
              center={[mapCenter.lat, mapCenter.lng]}
              zoom={16}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[mapCenter.lat, mapCenter.lng]} icon={MapMarketIcon()} />
            </MapContainer>
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                const googleMapsUrl = `https://www.google.com/maps?q=${mapCenter.lat},${mapCenter.lng}`
                window.open(googleMapsUrl, '_blank')
              }}
              variant="contained"
            >
              Open in Google Maps
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Section>
  )
}

export default FavoritesPage
