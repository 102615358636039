import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import React, { FC, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import myProfile from '~/_mock/myProfile'
import UserAvatar from '~/components/UserAvatar'
import translationLink from '~/helpers/utils/translationLink'
import { apiSlice } from '~/redux/features/api/api-slice'
import { authActions } from '~/redux/features/auth/auth-slice'
import { cookiesActions } from '~/redux/features/cookies/cookies-slice'
import { navigationActions } from '~/redux/features/navigation/navigation-slice'
import { useAppDispatch } from '~/redux/hooks'
import { MyProfileProps } from './MyProfile.types'

const MyProfile: FC<MyProfileProps> = ({ sx }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) =>
    setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const dispatch = useAppDispatch()

  const handleLogOut = () => {
    handleClose()
    dispatch(apiSlice.util.resetApiState())
    dispatch(authActions.reset())
    dispatch(cookiesActions.reset())
    dispatch(navigationActions.setIntendedRoute(null))
    window.location.reload()
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleClick} color="inherit">
          <UserAvatar />
        </IconButton>
      </Tooltip>

      <Menu
        id="my-profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {myProfile.map(({ to, label }) => (
          <MenuItem key={to} component={Link} to={translationLink(to)} onClick={handleClose}>
            {t(label)}
          </MenuItem>
        ))}

        <MenuItem onClick={handleLogOut}>{t('ui.button.signOut')}</MenuItem>
      </Menu>
    </Box>
  )
}
export default MyProfile
