const cookie = {
  accept_all: 'Tout accepter',
  accept_required: 'Accepter requis',
  always_active: 'Toujours actif',
  description:
    "Nous n'avons pas d'offres traditionnelles, mais nous fournissons des services de réservation en ligne de haute qualité. Pour garantir que votre expérience soit aussi fluide que possible, nous utilisons des technologies de stockage et d'accès aux informations de l'appareil. En acceptant ces technologies, nous pouvons traiter des données telles que le comportement de navigation ou des identifiants uniques sur ce site Web. Si vous choisissez de ne pas donner votre consentement ou de le retirer, certaines fonctionnalités peuvent être affectées.",
  message: {
    functional:
      "L'utilisation du stockage ou de l'accès technique est impérative pour remplir l'objectif légitime de faciliter l'utilisation d'un service particulier explicitement demandé par l'abonné ou l'utilisateur, ou dans le but exclusif de transmettre un message sur un réseau de communications électroniques.",
    marketing:
      "Le stockage ou l'accès technique est essentiel pour établir des profils d'utilisateurs, diffuser des publicits ou surveiller l'activité des utilisateurs sur un site Web ou sur différents sites Web pour atteindre des objectifs marketing similaires.",
  },
  save_settings: 'Enregistrer les paramètres',
  title: 'Gérer le consentement aux cookies',
  types: { functional: 'Fonctionnel', marketing: 'Marketing' },
  view_settings: 'Voir les paramètres',
}

export default cookie
