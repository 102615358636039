import AddIcon from '@mui/icons-material/Add'
import { Fab, Paper, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ReusableTableSkeleton from '~/components/Skeleton/ReusableTableSkeleton'
import ReusableTable from '~/components/Table/ReusableTable'
import Section from '~/components/UI/Section'
import translationLink from '~/helpers/utils/translationLink'
import { useUserListPostsQuery } from '~/redux/features/api/management-api-slice'

const CreateFirstPostLink = () => (
  <Stack direction="row" padding={2}>
    <Typography component="h1" variant="h5" marginRight={2}>
      Ready to create your very first Product?
    </Typography>
    <Link to={translationLink('/management/posts/new')}>
      <Fab color="primary" size="small" aria-label="add product" variant="extended">
        <AddIcon />
      </Fab>
    </Link>
  </Stack>
)

const UserPostsPage = () => {
  const postsQuery = useUserListPostsQuery({})

  // maybe remove the return of this information from the db if not used
  const columns_to_hide = ['id', 'admin', 'thumbnail', 'is_favorite', 'created_at', 'description']

  return (
    <Section
      size="big"
      padding={[
        { target: 'top', value: 'normal' },
        { target: 'bottom', value: 'none' },
      ]}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          borderRadius: 2,
          backgroundColor: 'background.paper',
          justifyContent: 'space-between',
          display: 'flex',
          ':hover': {
            boxShadow:
              '0px 3px 3px -2px rgba(0,0,0,0.8),0px 3px 4px 0px rgba(0,0,0,0.20),0px 1px 8px 0px rgba(0,0,0,0.18)',
          },
        }}
      >
        {postsQuery.isLoading && <ReusableTableSkeleton />}

        {postsQuery.isSuccess && !!postsQuery.data.results.length && (
          <Stack direction={'column'} spacing={2} width={'100%'}>
            <Typography variant="h6" sx={{ color: 'text.primary', marginBottom: 3 }}>
              Services
            </Typography>
            <ReusableTable
              data={postsQuery.data.results}
              columns_to_hide={columns_to_hide}
              actionPath="posts"
            />
          </Stack>
        )}

        {postsQuery.isSuccess && !postsQuery.data.results.length && <CreateFirstPostLink />}
      </Paper>
    </Section>
  )
}

export default UserPostsPage
