import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Form = styled(Box, {
  target: 'GuiForm-root',
  shouldForwardProp: (prop: string) => !['size', 'register'].includes(prop),
})<{ size?: 'small' | 'normal' }>(({ theme, size }) => {
  if (size === 'small') {
    return {
      display: 'grid',
      alignItems: 'center',
      width: 'stretch',
      gap: theme.spacing(2),
      '--grid-columns': 'repeat(1, 1fr)',
      gridTemplateColumns: 'var(--grid-columns)',

      [theme.breakpoints.up('md')]: {
        '--grid-columns': 'repeat(6, 1fr)',

        '& > *': {
          gridColumn: '2 / span 4',
        },
      },
    }
  }
})
