import { brand } from "../../../config/brand-config";
import { CookiesState } from "./cookies-slice";

const COOKIES_TAG = `${brand.name}:cookies`;

export const getCachedCookies = (): CookiesState => {
  try {
    const storedCookies = localStorage.getItem(COOKIES_TAG);
    return storedCookies ? JSON.parse(storedCookies) : { cookies: [] };
  } catch (error) {
    clearCachedCookies();
    console.error(error);
  }
  return { cookies: [] };
};

export const cacheCookies = (cookies: CookiesState) => {
  localStorage.setItem(COOKIES_TAG, JSON.stringify(cookies));
};

export const clearCachedCookies = () => {
  localStorage.removeItem(COOKIES_TAG);
};
