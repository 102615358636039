import { useTranslation } from 'react-i18next'
import images from '~/_mock/images'
import Team from '~/components/Layout/Team'
import Media from '~/components/Media'

const Page = () => {
  const { t } = useTranslation()
  const { urls, alt_description: alt } = { ...images?.[2] }

  return (
    <>
      <Media
        alignContent="center"
        title={t('page.about.title')}
        description={t('page.about.description')}
        size="small"
        media={{
          type: 'image',
          item: [{ src: urls?.regular, alt }],
        }}
      />

      <Team textAlign="center" />
    </>
  )
}

export default Page
