const t = {
  add: 'Ajouter',
  back: 'Retour',
  cancel: 'Annuler',
  close: 'Fermer',
  delete: 'Supprimer',
  edit: 'Editer',
  no: 'Non',
  register: 'Enregistrer',
  reset: 'Réinitialiser',
  save: 'Enregistrer',
  send: 'Envoyer',
  signIn: 'Se connecter',
  signInWithGoogle: 'Se connecter avec Google',
  signOut: 'Se déconnecter',
  signUp: "S'inscrire",
  signUpWithGoogle: "S'inscrire avec Google",
  submit: 'Soumettre',
  update: 'Mettre à jour',
  view: 'Voir',
  yes: 'Oui',
}

export default t
