export const NEWSLETTER = {
  url: 'newsletter',
  method: {
    POST: 'POST',
  },
} as const

export const POSTS = {
  url: 'post',
  method: {
    GET: 'GET',
  },
} as const

export const CATEGORY = {
  url: 'category',
  method: {
    GET: 'GET',
  },
} as const
