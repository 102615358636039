type ApiConfigType = {
  url: string;
};

class ApiConfig {
  private static instance: ApiConfig;
  config: ApiConfigType;

  private constructor() {
    this.config = {
      url: process.env.REACT_APP_API_ENDPOINT!,
    };
  }

  static getInstance(): ApiConfig {
    if (!ApiConfig.instance) {
      ApiConfig.instance = new ApiConfig();
    }
    return ApiConfig.instance;
  }
}

export const config = ApiConfig.getInstance();
