import { brand } from '~/config/brand-config'

const ACCESS_TAG = `${brand.name}:access`
const REFRESH_TAG = `${brand.name}:refresh`
const ACCOUNT_DETAIL_TAG = `${brand.name}:userDetail`

export const getAccessToken = () => localStorage.getItem(ACCESS_TAG)

export const getCachedAuth = () => {
  try {
    const storedAccess = getAccessToken()
    const storedRefresh = localStorage.getItem(REFRESH_TAG)

    if (storedAccess && storedRefresh) {
      const storedAccountDetail = localStorage.getItem(ACCOUNT_DETAIL_TAG)
      const parsedStoredAccountDetail = JSON.parse(storedAccountDetail!)

      return {
        access: storedAccess,
        refresh: storedRefresh,
        account_detail: parsedStoredAccountDetail,
      }
    }
  } catch (error) {
    clearCachedAuth()
    console.error(error)
  }
  return { access: null, refresh: null }
}

export const cacheAuth = (access: string, refresh: string, accountDetail: any) => {
  localStorage.setItem(ACCESS_TAG, access)
  localStorage.setItem(REFRESH_TAG, refresh)
  localStorage.setItem(ACCOUNT_DETAIL_TAG, JSON.stringify(accountDetail))
}

export const clearCachedAuth = () => {
  localStorage.removeItem(ACCESS_TAG)
  localStorage.removeItem(REFRESH_TAG)
  localStorage.removeItem(ACCOUNT_DETAIL_TAG)
}
