import { GridProps } from '@mui/material/Grid'
import { default as Grid } from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

const ColStyled = styled(Grid, {
  shouldForwardProp: (prop: any) => !['item'].includes(prop),
})<GridProps>(({ theme }) => ({
  '--gutter': theme.spacing(2),
  position: 'relative',
  paddingInline: 'var(--gutter)',

  [theme.breakpoints.up('md')]: {
    '--gutter': theme.spacing(3),
  },
}))

export type ColGridProps = Omit<GridProps, 'key'> & {
  xsOffset?: number | 'auto'
  smOffset?: number | 'auto'
  mdOffset?: number | 'auto'
  lgOffset?: number | 'auto'
  xlOffset?: number | 'auto'
  xxlOffset?: number | 'auto'
}

const Col = forwardRef<HTMLDivElement, ColGridProps>(({ children, ...rest }, ref) => (
  <ColStyled ref={ref} item {...rest}>
    {children}
  </ColStyled>
))

Col.displayName = 'Col'

export default Col
