import RegisterForm from '~/components/FormRegister'
import Section from '~/components/UI/Section'

const RegisterPage = () => (
  <Section size="small">
    <RegisterForm />
  </Section>
)

export default RegisterPage
