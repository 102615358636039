import { createTheme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/system/createTheme'
import { color } from './color'

export type IThemeOptions = ThemeOptions

export const fluidType = (
  minScreen: number,
  maxScreen: number,
  minValue: number,
  maxValue: number,
) => {
  const vw = (maxValue - minValue) / (maxScreen - minScreen)
  const px = minValue - vw * minScreen

  let sign = '+'
  if (px < 0) sign = '-'

  return `calc(${vw * 100}vw ${sign} ${px}px)`
}

const { black, white, ...restColors } = Object.assign({}, color)

export const themeDefaults = {
  shape: {
    borderRadius: 8,
    borderRadiusSmall: 4,
    borderRadiusLarge: 12,
  },
  palette: {
    ...restColors,
    common: {
      black: color.black,
      white: color.white,
    },
    divider: '#CCCCCC',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
} satisfies IThemeOptions

export const createThemeWithDefaults = (theme?: IThemeOptions) => {
  let defaultTheme = createTheme(themeDefaults)

  defaultTheme = createTheme(defaultTheme, {
    typography: {
      fontWeightSemiBold: 600,
      fontSize: 16,
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            textDecorationColor: 'transparent',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            borderRadius: defaultTheme.shape.borderRadius,

            '&.MuiButton-text': {
              ':hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },
    },
  })

  if (theme) {
    defaultTheme = createTheme(defaultTheme, theme || {})
  }

  return defaultTheme
}
